<template>
  <div :id="'taBle' + taBleDt.id" class="taBle">
    <div :key="z" class="cloum" v-for="(cloum, z) in taBleDt.data">
      <div class="hd">
        <div :key="i" class="item" v-for="(it, i) in taBleDt.lab">
          <span v-html="it"></span>
        </div>
      </div>
      <div class="bd">
        <div :key="i" class="line" v-for="(item, i) in cloum">
          <!-- <div>{{ item["status"]  }}</div> -->
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <div :key="n" class="item" v-for="(v, k, n) in item" v-if="n<3">
            <div class="bx">
              <div class="cont" :class="{'cout-rank': n === 0}">
                <div :class="bd_old && bd_old[z][i][k] != v ? 'run' : ''">
                  <p>{{ bd_old? bd_old[z][i][k] : "" }}</p>
                  <p>{{ v }}</p>
                </div>
                <div v-if="n === 0 && item['status'] != null">
                  <img alt src="../assets/icon/green-one-up.png" v-if="item['status'] == 1" />
                  <img alt src="../assets/icon/green-double-up.png" v-if="item['status'] == 2" />
                  <img alt src="../assets/icon/red-one-down.png" v-if="item['status'] == -1" />
                  <img alt src="../assets/icon/red-double-down.png" v-if="item['status'] == -2" />
                  <img alt src="../assets/icon/none.png" v-if="item['status'] == 0" />
                </div>
              </div>
              {{ getDW(n) }}
              <span :class="v >= 0 ? 'up' : ''" class="ic" v-if="k == 'mom'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  // components: {
  //   vueSeamlessScroll
  // },
  name: "taBle",
  props: {
    taBleDt: {
      type: Object
    },
    tableIndex: {
      type: String
    }
  },
  data() {
    return {
      index: 0,
      bd_new: null,
      bd_old: null,
      scroll_new: null,
      scroll_old: null,
      timer: null,
      trans: 0,
      isTv: false
    };
  },
  watch: {
    tableIndex() {
      let that = this;
      let code = navigator.userAgent;

      if (this.isTv) {
        document.getElementsByClassName("seamless")[0].removeAttribute("style");
        document.getElementsByClassName("seamless")[1].removeAttribute("style");
        this.trans = 0;
        that.setUpInterval();
      }
    },
    taBleDt(newv, oldv) {
      let code = navigator.userAgent;
      let that = this;
      console.log("表" + that.taBleDt.id, newv);
      setTimeout(function () {
        that.bd_old = [].concat(that.taBleDt.data);
        console.log("old", that.bd_old);
        // that.scroll_old = [].concat(that.taBleDt.data.bd);
      }, 1000);
    }
  },
  computed: {
    // seamlessScrollOption(e) {
    //   console.log(e);
    //   return {
    //     step: 0.4, // 数值越大速度滚动越快
    //     limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
    //     hoverStop: false, // 是否开启鼠标悬停stop
    //     direction: 1, // 0向下 1向上 2向左 3向右
    //     openWatch: true, // 开启数据实时监控刷新dom
    //     singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //     singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //     waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
    //   };
    // }
  },
  methods: {
    // ScrollEnd: function(){
    //   console.log("ScrollEnd")
    // },
    getDW(n) {
      let d = "";
      if (n == 0 || n == 2) {
        d = "";
      }
      if (n == 3) {
        d = "%";
      }
      if (n == 1) {
        if (this.taBleDt.id == 0) {
          d = "";
        }

        if (this.taBleDt.id == 2) {
          d = "";
        }
        if (this.taBleDt.id == 1) {
          d = "";
        }
        if (this.taBleDt.id == 3) {
          d = "";
        }
      }
      return d;
    },
    removeTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    setTimer() {
      let that = this;
      let index = 0;
      that.removeTimer();
      switch (that.tableIndex) {
        case "0":
          index = 3;
          break;
        case "1":
          index = 1;
          break;
      }

      if (index == 3 || index == 1) {
        const scrollHeight = document.getElementById(
          "scroll" + index
        ).clientHeight;
        const clientHeight = document.getElementById(
          "seamless" + index
        ).clientHeight;
        const heightDifference = scrollHeight - clientHeight;

        document
          .getElementById("seamless" + index)
          .setAttribute(
            "style",
            "transform: translate3d(0," +
              heightDifference +
              "px,0);transition: transform linear 60s;"
          );
        let tt = 0;
        that.timer = setInterval(() => {
          tt++;
          console.log(tt);
          if (tt >= 60) {
            that.$emit("getScrollend", "end");
            that.removeTimer();
          }
        }, 1000);
        // if (
        //     !document.getElementById("seamless" + index) ||
        //     (!that.scroll_old.length && !that.bd_old.length)
        //   ) {
        //     return;
        //   }
        // that.timer = setInterval(() => {
        //   if (
        //     !document.getElementById("seamless" + index) ||
        //     (!that.scroll_old.length && !that.bd_old.length)
        //   ) {
        //     return;
        //   }
        //   const scrollHeight = document.getElementById("scroll" + index)
        //     .clientHeight;
        //   const clientHeight = document.getElementById("seamless" + index)
        //     .clientHeight;
        //   const heightDifference = scrollHeight - clientHeight;
        //   if (that.trans < heightDifference) {
        //     that.$emit("getScrollend", "end");

        //     that.setUpInterval();
        //     return;
        //   }
        //   that.trans--;
        //   document
        //     .getElementById("seamless" + index)
        //     .setAttribute(
        //       "style",
        //       "transform: translate3d(0," +
        //         that.trans +
        //         "px,0);transition: transform linear 0.3s;"
        //     );
        // }, 44);
      }
    },
    setUpInterval() {
      let that = this;
      that.setTimer();
      // setTimeout(function() {
      //   that.setTimer();
      // }, 1000);
    },
    monseenter() {
      this.removeTimer();
    },
    mouseleave() {
      this.setUpInterval();
    }
  },
  mounted() {
    let that = this;
    let code = navigator.userAgent;
    //code = 'dfgdfgf'
    if (code.indexOf("Mozilla") > -1) {
      this.isTv = !true;
    } else {
      this.isTv = true;
      // let v = setInterval(function(){
      //   if(that.scroll_old){
      //     that.setUpInterval();
      //     clearInterval(v)
      //   }
      // },100)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.taBle {
  margin-top: calc(10px * 2);
}

h1 {
  font-size: calc(40px * 2);
}

.item {
  /* width: 90px; */
  width: calc(68px * 2);
  flex-grow: 1;
  height: 100%;
  flex-shrink: 0;
}

.item:nth-child(2) {
  width: calc(166px * 2);
  flex-shrink: 0;
}
.item:nth-child(3) {
  flex-shrink: 0;
}
.item .bx {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hd,
.line {
  display: flex;
  font-size: calc(12px * 2);
  justify-content: space-between;
  align-items: center;
  font-family: BrilliantCut-Regular;
}

.hd {
  height: calc(28px * 2);
  text-align: center;
  margin-bottom: calc(12px * 2);
}

.hd .item {
  background-color: rgba(247, 205, 205, 1);
  border-right: calc(2px * 2) solid rgba(247, 233, 233, 1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-mind .hd .item {
  background-color: rgba(230, 230, 230, 1);
  border-right: calc(2px * 2) solid rgba(245, 245, 245, 1);
  box-sizing: border-box;
}

.hd .item:last-child {
  border: none;
}

.section-right .hd {
  margin-bottom: calc(10px * 2);
}

.section-right .hd .item {
  background-color: #e6e6e6;
}

.bd .line {
  height: calc(24px * 2);
  font-size: calc(12px * 2);
  border-bottom: calc(1px * 2) solid rgba(153, 153, 153, 0.3);
}

.section-mind .bd .line {
  height: calc(28px * 2);
}

.section-right .bd .line {
  height: calc(33px * 2);
}

.bd .line .ic {
  width: calc(8px * 2);
  height: calc(14px * 2);
  margin-left: calc(6px * 2);
  background: url(../assets/icons.png) no-repeat;
  background-size: 100% auto;
  background-position: 0 0;
  transition: background linear 0.3s;
}

.bd .line .ic.up {
  background-position: 0 calc(-14px * 2);
  transition: background linear 0.3s;
}

.cont {
  height: calc(16px * 2);
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  display: flex;
  justify-content: space-around;
}

.cout-rank {
  width: calc(36px * 2);
}

.cont::-webkit-scrollbar {
  height: 0px;
}

.line .cont p {
  height: calc(16px * 2);
  line-height: calc(16px * 2);
  white-space: nowrap;
  text-align: center;
  /* display: flex;
  align-items: center; */
}
.line .item:nth-child(2) .cont p {
  text-align: left;
}
.line .cont p span {
  display: inline-block;
  vertical-align: middle;
}
.cont .run {
  transform: translateY(calc(-16px * 2));
  transition: transform ease-out 0.3s;
}

.new {
  /* width: 36px;
  height: 16px; */
  border-radius: 100px;
  background-color: rgba(230, 202, 202, 1);
  color: rgba(183, 29, 34, 1);
  font-size: calc(9px * 2);
  text-align: center;
  line-height: 1;
  padding: calc(4px * 2) calc(6px * 2);
}
.scroll::-webkit-scrollbar {
  width: 0px !important;
}
.scroll::-webkit-scrollbar-track {
  width: 0px !important;
}
.scroll::-webkit-scrollbar-track-piece {
}
.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
.line .item:nth-child(2) .bx {
  justify-content: flex-start;
}
</style>
