<template>
  <div class="login">
    <img src="../assets/logo@3x.png" class="logo" />
    <h2>Beyond Traffic DASHBOARD</h2>
    <div class="form">
      <div class="line">
        <div class="lb">Username</div>
        <div class="cont">
          <input v-model="formData.user_name" />
        </div>
      </div>
      <div class="line">
        <div class="lb">Password</div>
        <div class="cont">
          <input v-model="formData.password" />
        </div>
      </div>
      <div class="line">
        <div class="lb">Verification Code</div>
        <div class="cont">
          <input v-model="formData.code" />
          <img :src="apiurl + '/verify?a=' + formData.a" @click="change" />
        </div>
      </div>
      <div class="submit" @click="submit">Log in</div>
    </div>
    <div class="msg" v-if="msg">{{ msg }}</div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      apiurl: null,
      formData: {
        user_name: null,
        password: null,
        code: null,
        a: null
      },
      msg: null,
      keyimg: null,
      submiting: true
    };
  },
  methods: {
    change() {
      this.formData.a = '' + new Date().getTime()
    },
    submit() {
      let that = this;
      let isok = true
      that.msg = ''
      if (!that.formData) {
        that.msg = "请输入账号和密码！";
        return;
      }
      for (let k in that.formData) {
        if (!that.formData[k]) {
          switch (k) {
            case "user_name":
              that.msg = "请输入账号！";
              break;
            case "password":
              that.msg = "请输入密码！";
              break;
            case "code":
              that.msg = "请输入验证码！";
              break;
          }
          setTimeout(function () {
            that.msg = ''
          }, 2000)
          isok = false
          return;
        }
      }
      if (isok) {
        // console.log(that.formData)
        if (!that.submiting) return
        that.submiting = false
        that.$axios({
          url: that.$api.commonUrl + "/login",
          method: "post",
          data: that.formData
        })
          .then(res => {
            console.log(res)
            that.submiting = true
            if (res.data.code == 200) {
              window.localStorage.setItem('cart-token', res.data.data);
              console.log('token??????', res.data.data)
              that.$router.push({ name: "Index" });
            } else {
              that.msg = res.data.msg
              setTimeout(function () {
                that.msg = ''
              }, 3000)
            }
          })
          .catch(res => {
            that.submiting = true
          });
      }
    }
  },
  mounted() {
    let that = this;
    that.formData.a = '' + new Date().getTime()
    that.apiurl = that.$api.commonUrl
    document.getElementById('app').style.transformOrigin = 'left 50%'
  }
};
</script>
<style scoped>
input,
button,
textarea {
  font-family: "PingFang";
  padding: 0;
  background: none;
  outline: none;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.8);
}

::-webkit-input-placeholder {
  color: #999;
}

.login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: calc(75px*2);
  width: auto;
  margin-bottom: calc(95px*2);
}

h2 {
  text-align: center;
  font-size: calc(36px*2);
  font-family: "BrilliantCut-Bold";
  margin-bottom: calc(67px*2);
}

.form .line {
  /* display: flex;
  align-items: center; */
  font-size: calc(26px*2);
  margin-bottom: calc(10px*2);
}

.form .line .lb {
  /* width: 90px; */
  /* text-align: justify;
  text-justify: distribute-all-lines;
  text-align-last: justify; */
}

input {
  width: calc(461px*2);
  height: calc(52px*2);
  border: calc(1px*2) solid #000;
  box-sizing: border-box;
  padding: 0 calc(20px*2);
  font-size: calc(27px*2);
}

.submit {
  width: 100%;
  height: calc(52px*2);
  background-color: rgba(56, 56, 56, 1);
  font-size: calc(24px*2);
  color: #fff;
  text-align: center;
  line-height: calc(52px*2);
  margin-top: calc(38px*2);
  cursor: pointer;
}

.line:nth-child(3) input {
  width: calc(294px*2);
}

.line .cont {
  display: flex;
  align-items: center;
}

.line img {
  width: calc(157px*2);
  height: calc(52px*2);
  margin-left: calc(7px*2);
  cursor: pointer;
}

.msg {
  position: fixed;
  width: calc(400px*2);
  height: calc(80px*2);
  text-align: center;
  line-height: calc(80px*2);
  background: #fff;
  font-size: calc(22px*2);
  color: #000;
  box-shadow: 0 0px calc(30px*2) rgb(0 0 0 / 10%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}</style>