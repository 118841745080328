import Vue from "vue";
import VueRouter from "vue-router";

import Index from "@/views/index.vue";
import Login from "@/views/login";
import tvLogin from "@/views/logintv";

import mbIndex from "@/views/mobile/index";
import mbLogin from "@/views/mobile/login";
import Invitation from "@/views/mobile/Invitation";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/mbindex",
    name: "mbIndex",
    component: mbIndex,
  },
  {
    path: "/mblogin",
    name: "mbLogin",
    component: mbLogin,
  },
  {
    path: "/logintv",
    name: "tvLogin",
    component: tvLogin,
  },
  {
    path: "/Invitation",
    name: "Invitation",
    component: Invitation,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
