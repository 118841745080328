<template>
  <div style=" position: relative">
    <div :id="'EchartBarLine'+EchartBarLineDt.id" class="EchartBarLine"></div>
    <div class="xname">(Month)</div>
  </div>
</template>

<script>
export default {
  name: "EchartBarLine",
  props: {
    EchartBarLineDt: Object,
    styles: Object
  },
  data() {
    return {
      el: null,
      option: {
        title: {
          text: "(Traffic)",
          textStyle: {
            fontFamily: "BrilliantCut-Medium",
            fontSize: 24,
            color: "#000"
          },
          top: 20
        },
        color: ["#FFC9C9", "#EB9B9B"],
        grid: {
          left:'0',
          top:'14%',
          bottom: "5%",
          right:'10%',
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999"
            },
            label:{
              fontSize: 24
            }
          },
          textStyle: {
            fontSize:28
          }
        },

        legend: {
          top:0,
          right: '10%',
          itemHeight: 22,
          textStyle: {
            fontFamily: "BrilliantCut-Regular",
            fontSize: 22,
            color: "#000000"
          },
          selectedMode: false,
        },
        xAxis: {
          type: "category",
          offset: 2,
          data: [
            "Dec",
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov"
          ],
          axisPointer: {
            type: "shadow"
          },
          axisTick: {
            alignWithLabel: true,
            length:10,
            lineStyle: {
              color:'#000',
              width:2
            }
          },
          axisLine: {
            onZero: false,
            lineStyle:{
              width:2,
              color:'#000'
            }
          },
          axisLabel: {
            fontFamily: "BrilliantCut-Regular",
            fontSize: 24,
            color: '#000',
            margin:20
          }
        },
        yAxis: [
          // {
          //   type: "value",
          //   name: "",
          //   scale: true,
          //   // min: '',
          //   // max: function (value) {
          //   //     return value.max;
          //   // },
          //   interval: 5,
          //   axisLabel: {
          //     formatter: function(value, index) {

          //       if (value.toString().indexOf(".")>-1) {

          //         if (value.toString().split(".")[1].length > 0) {
          //           return value.toFixed(1) + "%";
          //         } else {
          //           return value + "%";
          //         }
          //       } else {
          //         return value + "%";
          //       }
          //     },
          //     fontFamily: "BrilliantCut-Regular",
          //     fontSize: 10
          //   }
          // },
          {
            type: "value",
            name: "",
            scale: true,
            //  min: function (value) {
            //      return value.min;
            //  },
            //  max: function (value) {
            //      return value.max;
            //  },
            interval: 5,
            axisLabel: {
              formatter: "{value}",
              fontFamily: "BrilliantCut-Regular",
              fontSize: 24,
              color: '#000'
            }
          }
        ],
        series: []
      }
    };
  },
  methods: {
  },
  mounted() {
    let that = this;

    // let screenWidth = document.body.clientWidth
    // this.option.legend.itemWidth = this.option.legend.itemHeight = (this.option.legend.itemWidth*screenWidth)/1920
    // this.option.legend.textStyle.fontSize = (this.option.legend.textStyle.fontSize*screenWidth)/1920
    // this.option.series[0].label.fontSize =(this.option.series[0].label.fontSize*screenWidth)/1920
    that.$nextTick(() => {
      let echartDom = document.getElementById(
        "EchartBarLine" + that.EchartBarLineDt.id
      );
      let myChart = that.$echarts.init(echartDom,null, { renderer: 'svg' });
      that.el = echartDom;
      if (that.styles) {
        that.option.grid = that.styles.grid
        that.option.legend = that.styles.legend;
        that.option.yAxis[0].axisLabel.fontSize = that.option.yAxis[1].axisLabel.fontSize = that.option.xAxis.axisLabel.fontSize =
          that.styles.xAxis.axisLabel.fontSize;
      }

      myChart.setOption(that.option);
    });
  },
  watch: {
    EchartBarLineDt(newv) {
      let that = this;
      let series = [];
      let trend = [];
      let entry = [];
      let current = [];
      let barWidth = 13;

      that.option.xAxis.data = [];
      newv.data.map(it => {
        that.option.xAxis.data.push(it.dateStr);
        trend.push(it.trend);

        if (newv.id == 2) {
          entry.push(it.lastAvgTime);
          current.push(it.thisAvgTime);
        } else {
          if (newv.id == 1) {
            barWidth = 16;
          }
          entry.push(it.lastPeople);
          current.push(it.thisPeople);
        }
      });

      console.log(newv, trend, entry);
      // let y1max =  Math.max(...trend)
      //   let y1min =  Math.min(...trend)
      //   let y2max =  Math.max(...current)
      //   let y2min =  Math.min(...current)
      //    that.option.yAxis[0].interval =((y1max  -  y1min )/5)
      switch (newv.id) {
        case 0:
          (that.option.color = ["#FFC9C9", "#EB9B9B"]),
            // (series[0] = {
            //   name: "Traffic Trend",
            //   type: "line",
            //   yAxisIndex: 0,
            //   tooltip: {
            //     valueFormatter: function(value) {
            //       return value + " %";
            //     }
            //   },
            //   data: trend
            // });
          series[0] = {
            name: "Entry Traffic(K)",
            type: "bar",
            barGap: 0,
            barWidth: barWidth,
            yAxisIndex: 0,
            tooltip: {
              valueFormatter: function(value) {
                return value + " ";
              }
            },
            data: current
          };
          break;
        case 1:
          // series[0] = {
          //   name: "Traffic Trend",
          //   type: "line",
          //   yAxisIndex: 0,
          //   tooltip: {
          //     valueFormatter: function(value) {
          //       return value + " %";
          //     }
          //   },
          //   data: trend
          // };
          series[0] = {
            // name: "Last Year Traffic(K)",
            name: newv.thisDate,
            type: "bar",
            barGap: 0,
            barWidth: barWidth,
            yAxisIndex: 0,
            tooltip: {
              valueFormatter: function(value) {
                return value + " ";
              }
            },
            data: entry
          };
          series[1] = {
            // name: "Current Year Traffic(K)",
            name: newv.lastDate,
            type: "bar",
            barGap: 0,
            barWidth: barWidth,
            yAxisIndex: 0,
            tooltip: {
              valueFormatter: function(value) {
                return value + " ";
              }
            },
            data: current
          };
          // let narr =  entry.concat(current)
          //  y2max =  Math.max(...narr)
          //  y2min =  Math.min(...narr)
          // that.option.yAxis[0].interval =(((y1max  -  y1min ).toFixed(1))/5)
          break;
        case 2:
          (that.option.color = ["#FFC9C9", "#EB9B9B"]),
            // (series[0] = {
            //   name: "Stay Length Trend",
            //   type: "line",
            //   yAxisIndex: 0,
            //   tooltip: {
            //     valueFormatter: function(value) {
            //       return value + " %";
            //     }
            //   },
            //   data: trend
            // });
          series[0] = {
            name: "Stay Length(min)",
            type: "bar",
            barGap: 0,
            barWidth: barWidth,
            yAxisIndex: 0,
            tooltip: {
              valueFormatter: function(value) {
                return value + " ";
              }
            },
            data: current
          };
          break;
      }
      // that.option.yAxis[0].min =  y1min
      // that.option.yAxis[0].max = y1max
      //
      // that.option.yAxis[1].min = y2min
      // that.option.yAxis[1].max = y2max
      // that.option.yAxis[1].interval = ((y2max  -  y2min )/5)
      that.option.series = series;

      let chart = that.$echarts.getInstanceByDom(that.el);
      chart.setOption(that.option);

      let y1max = chart.getModel().getComponent("yAxis").axis.scale
        ._extent[1];
      let y1min = chart.getModel().getComponent("yAxis").axis.scale
        ._extent[0];
      // let y2max = chart.getModel().getComponent("yAxis", 1).axis.scale
      //   ._extent[1];
      // let y2min = chart.getModel().getComponent("yAxis", 1).axis.scale
      //   ._extent[0];
      //that.option.yAxis[1].interval = (y2max - y2min) / 5;
      that.option.yAxis[0].interval = (y1max - y1min) / 5;
      chart.setOption(that.option);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.EchartBarLine {
  width: calc(620px*2);
  height: calc(280px*2);
  margin: 0 auto;
}
.tip {
  position: absolute;
  font-size: calc(9px*2);
  top: calc(-12px*2);
  right: 0;
}
.tip::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(45px*2);
  left: calc(-60px*2);
  border-bottom: calc(2px*2) dotted rgba(176, 36, 34, 0.7);
}
.xname{
  position: absolute;
  bottom: calc(14px*2);
  font-size: calc(12px*2);
  right: calc(10px*2);
  font-family: "BrilliantCut-Regular";
  color:#000
  }
</style>
