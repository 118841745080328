
let Util = {
    getParam(name) {
        var url = window.location.href;
        var urlOne = url.indexOf('#')?url.split('#')[1]:'';
        var urlParam = urlOne.indexOf('?')?urlOne.split('?')[1]:'';
        var paramArr = urlParam&&urlParam.indexOf('&')?urlParam.split('&'):'';
        var param = {};
        for (var i = 0; i < paramArr.length; i++) {
            param[paramArr[i].split('=')[0]] = paramArr[i].split('=')[1]
        }
        if (param[name]) {
            return param[name];
        } else {
            return null;
        }
    }
}

export default Util