<template>
  <div style="position: relative;">
    <div :id="'EchartLine'+EchartLineDt.id" class="EchartLine"></div>
    <div class="xname">(Date)</div>
  </div>
</template>

<script>
export default {
  name: "EchartLine",
  props: {
    EchartLineDt: Object,
    styles: Object
  },
  data() {
    return {
      el: null,
      option: {
        color: [
          "rgb(235, 155, 155)"
          // "#A14382",
          // "#D65451",
          // "#62AFE3",
          // "#28AF39",
          // "#FD9847",
          // "#FFE67E"
        ],
        title: {
          text: "(K)",
          textStyle: {
            fontFamily: "BrilliantCut-Medium",
            fontSize: 24,
            color: "#000"
          },
          top: 30
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
              fontSize: 24
            },
          },
          textStyle: {
            fontSize: 28
          }
        },
        legend: {
          show: false,
          // icon: "rect",
          top: 28,
          right: 28,
          itemWidth: 24,
          itemHeight: 24,
          textStyle: {
            fontFamily: "BrilliantCut-Regular",
            fontSize: 22,
            color: "#000000"
          },
          selectedMode: false
        },

        grid: {
          top: "14%",
          left: "0",
          right: "10%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
            length: 10,
            lineStyle: {
              color: "#000",
              width: 2
            }
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              width: 2,
              color: "#000"
            }
          },
          // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLabel: {
            // formatter: "{value} °C"
            fontFamily: "BrilliantCut-Regular",
            fontSize: 24,
            color: "#000",
            margin: 20
          }
        },
        yAxis: {
          // name: "(Traffic)",
          // nameGap: "22",
          // nameLocation: "end",
          // nameTextStyle:{
          //   fontFamily: "BrilliantCut-Regular",
          //   fontSize: 12,
          //   color: "rgba(128, 128, 128, 1)"
          // },
          type: "value",
          axisLabel: {
            // formatter: "{value} °C"
            fontFamily: "BrilliantCut-Regular",
            fontSize: 24,
            color: "#000"
          }
        },
        series: [
          {
            name: "Traffic",
            type: "line",
            stack: "Total",
            // smooth: true,
            lineStyle: {
              width: 4
            },
            // showSymbol: false,
            // areaStyle: {
            //   opacity: 0.8
            // },
            emphasis: {
              focus: "series"
            },
            data: []
          }
          // {
          //   name: "Line 2",
          //   type: "line",
          //   stack: "Total",
          //   smooth: true,
          //   lineStyle: {
          //     width: 0
          //   },
          //   showSymbol: false,
          //   areaStyle: {
          //     opacity: 0.8
          //   },
          //   emphasis: {
          //     focus: "series"
          //   },
          //   data: [120, 282, 111, 234, 220, 340, 310]
          // },
          // {
          //   name: "Line 3",
          //   type: "line",
          //   stack: "Total",
          //   smooth: true,
          //   lineStyle: {
          //     width: 0
          //   },
          //   showSymbol: false,
          //   areaStyle: {
          //     opacity: 0.8
          //   },
          //   emphasis: {
          //     focus: "series"
          //   },
          //   data: [320, 132, 201, 334, 190, 130, 220]
          // },
          // {
          //   name: "Line 4",
          //   type: "line",
          //   stack: "Total",
          //   smooth: true,
          //   lineStyle: {
          //     width: 0
          //   },
          //   showSymbol: false,
          //   areaStyle: {
          //     opacity: 0.8
          //   },
          //   emphasis: {
          //     focus: "series"
          //   },
          //   data: [220, 402, 231, 134, 190, 230, 120]
          // },
          // {
          //   name: "Line 5",
          //   type: "line",
          //   stack: "Total",
          //   smooth: true,
          //   lineStyle: {
          //     width: 0
          //   },
          //   showSymbol: false,
          //   label: {
          //     show: true,
          //     position: "top"
          //   },
          //   areaStyle: {
          //     opacity: 0.8
          //   },
          //   emphasis: {
          //     focus: "series"
          //   },
          //   data: [220, 302, 181, 234, 210, 290, 150]
          // }
        ]
      }
    };
  },
  methods: {},
  mounted() {
    let id = 0;
    let that = this;
    // let screenWidth = document.body.clientWidth
    // this.option.legend.itemWidth = this.option.legend.itemHeight = (this.option.legend.itemWidth*screenWidth)/1920
    // this.option.legend.textStyle.fontSize = (this.option.legend.textStyle.fontSize*screenWidth)/1920
    // this.option.series[0].label.fontSize =(this.option.series[0].label.fontSize*screenWidth)/1920
    this.$nextTick(() => {
      id = that.EchartLineDt.id;
      let echartDom = document.getElementById("EchartLine" + id);
      that.el = echartDom;
      let myChart = that.$echarts.init(echartDom, null, { renderer: "svg" });
      if (that.styles) {
        that.option.xAxis.axisLabel.fontSize =
          that.styles.xAxis.axisLabel.fontSize;
        that.option.yAxis.axisLabel.fontSize =
          that.styles.yAxis.axisLabel.fontSize;
        //that.option.title.textStyle.fontSize = that.styles.title.textStyle.fontSize
        that.option.legend.top = that.styles.legend.top;
        that.option.legend.itemWidth = that.styles.legend.itemWidth;
        that.option.legend.itemHeight = that.styles.legend.itemHeight;
        that.option.legend.textStyle.fontSize =
          that.styles.legend.textStyle.fontSize;
        that.option.grid = that.styles.grid;
      }

      myChart.setOption(that.option);
    });
  },
  watch: {
    EchartLineDt(newv, oldv) {
      let that = this;
      let title = "";
      let x = [];
      let y = [];
      let series = [];
      let data = [];
      switch (newv.type) {
        case "day":
          title = "Daily Entry Traffic in last 7 Days";
          break;
        case "week":
          title = "WTD Entry Traffic in last 4 Weeks";
          break;
        case "month":
          title = "Entry Traffic in last 12 Months";
          break;
        case "day_now": {
          title = "Daily Entry Traffic in last 7 Days";
          break;
        }
      }
      switch (newv.type) {
        case "day_now": {
          // 组装x轴标签数据
          newv.list.data.map((item, i) => {
            let obj = [];
            item.map((it, i) => {
              obj.push(it.list);
            });
            console.log(newv.list.name[i]);
            that.option.series[i] = {
              data: obj,
              type: "line",
              name: newv.list.name[i]
            };
          });
          if (newv.list.data[0]) {
            newv.list.data[0].map((item, i) => {
              x.push(item.date);
            });
          }
          console.log(y);
          console.log(newv.list.name);
          that.option.legend.show = true;
          that.option.legend.show = true;
          break;
        }

        default: {
          // 组装x轴标签数据
          newv.list.map((it, i) => {
            x.push(it.date);
            y.push(it.list);
          });
          that.option.series[0].data = y;
          break;
        }
      }

      // 组装series数据
      // newv.list[0].list.map((item, i) => {
      //   data[i] = [];
      //   newv.list.map((it, n) => {
      //     data[i].push(it.list[i].people_count);
      //   });
      //   series[i] = {
      //     name: item.title,
      //     type: "line",
      //     stack: "Total",
      //     smooth: true,
      //     lineStyle: {
      //       width: 0
      //     },
      //     showSymbol: false,
      //     areaStyle: {
      //       opacity: 0.8
      //     },
      //     emphasis: {
      //       focus: "series"
      //     },
      //     label: {
      //       show: true,
      //       position: "top"
      //     },
      //     data: data[i]
      //   };
      // });

      //that.option.title.text = title;
      // that.option.series = series;
      that.option.xAxis.data = x;

      that.$echarts.getInstanceByDom(that.el).setOption(that.option);
      // console.log('>>>>>>>>>',that.option)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.EchartLine {
  width: calc(620px * 2);
  height: calc(280px * 2);
  margin: 0 auto;
}
.tip {
  position: absolute;
  font-size: calc(9px * 2);
  top: calc(-12px * 2);
  right: 0;
}
.tip::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(45px * 2);
  left: calc(-60px * 2);
  border-bottom: calc(2px * 2) dotted rgba(176, 36, 34, 0.7);
}
.xname {
  position: absolute;
  bottom: calc(9px * 2);
  font-size: calc(12px * 2);
  right: calc(10px * 2);
  font-family: "BrilliantCut-Regular";
  color: #000;
}
</style>
