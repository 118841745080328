import { render, staticRenderFns } from "./EchartPie.vue?vue&type=template&id=985e3706&scoped=true&"
import script from "./EchartPie.vue?vue&type=script&lang=js&"
export * from "./EchartPie.vue?vue&type=script&lang=js&"
import style0 from "./EchartPie.vue?vue&type=style&index=0&id=985e3706&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_y5o36p7qrshhm4v7l6yk7rwfsu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "985e3706",
  null
  
)

export default component.exports