// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from '@/router'
import store from '@/store'

import axios from 'axios'
import api from '@/api/index'
import util from '@/utils/utils'
import * as echarts from 'echarts'
import '@/assets/font/font.css'
// import 'lib-flexible'

Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$echarts = echarts
Vue.prototype.$util = util
Vue.prototype.$timer = null
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
