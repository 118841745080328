<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }
  },
  data() {
    return {
      scale_rate: 1
    };
  },
  mounted() {
    let that = this;
    let token = window.localStorage.getItem("cart-token");
    let invi = this.$util.getParam("invitationcode");//电视机设备号
    let code = navigator.userAgent

    //code = 'dfgdfgf'
    if (!this._isMobile()) {
      let client_width = document.body.clientWidth;
      let design_width = 3840;
      this.scale_rate = client_width / design_width;
      // document
      //   .querySelector('meta[name="viewport"]')
      //   .setAttribute("content", "width=1920,user-scalable=0");
      document.querySelector("body").classList.add("body");
      document.getElementById("app").classList.add("app");
    }

    if (invi) {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute("content", "width=750,user-scalable=0");
      document.querySelector("body").classList.add("body");
      document.getElementById("app").classList.add("app2");
      return;
    }
    if (code.indexOf("Mozilla") > -1) {

      if (this._isMobile()) {

        if (!token) {
          this.$router.push({ name: "mbLogin" });
        } else {
          this.$router.push({ name: "mbIndex" });
        }

        document
          .querySelector('meta[name="viewport"]')
          .setAttribute("content", "width=750,user-scalable=0");
        document.querySelector("body").classList.add("body");
        document.getElementById("app").classList.add("app2");
      } else {
        // pc端使用缩放
        document.getElementById('app').setAttribute('style', 'transform:translateZ(0) perspective(1px) scale(' + this.scale_rate + ');transform-origin:top left;width:3840px')
        if (!token) {
          this.$router.push({ name: "Login" });
        } else {
          this.$router.push({ name: "Index" });
        }
      }
    } else {
      // 电视机端
      that.$axios({
        url: that.$api.commonUrl + "/is_bind?apk_code=" + code,
        method: "get"
      })
        .then(res => {

          if (res.data.code == 200) {
            that.$router.push({ name: "Index" });
          }
          else {
            that.$router.push({ name: "tvLogin" });
            document
              .querySelector("body")
              .setAttribute("style", "overflow:hidden");
          }
        });
    }
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: BrilliantCut-Light, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

.app {
  width: 100%;
  height: 100%;
}

.app2 {
  height: 100%;
}

.body {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
