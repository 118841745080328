<template>
  
    <div  class="echartBox">
    <div id="EchartPie"></div>
  </div>
  

</template>

<script>
export default {
  name: "EchartPie",
  props: {
    EchartPieDt: {
      type: Object,
      deep: true
    },
    styles: {
      type: Object
    }
  },

  data() {
    return {
      myChart: null,
      option: {
        color: [
          "#A14382",
          "#D65451",
          "#62AFE3",
          "#28AF39",
          "#FD9847",
          "#FFE67E"
        ],
       
        tooltip: {
          trigger: "item",
          formatter: "{b} :{d}%"
        },
        legend: {
          top: "center",
          right: "6%",
          left: 'auto',
          orient: "vertical",
          itemWidth: 24,
          itemHeight: 24,
          icon: "rect",
          textStyle: {
            fontFamily: "BrilliantCut-Regular",
            fontSize: 22,
            color: "#000000"
          },
          selectedMode: false,
          itemGap:20,
        },
        series: {
            name: "",
            type: "pie",
            left: "-40%",
            radius: ["0%", "62%"],
            top: '0',
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside",
              rotate: 'true',
              formatter: "{d}%",
              fontSize: 28,
              fontFamily: "BrilliantCut-Regular",
              color:'#000',
              
            },

            labelLine: {
              show: true,
              length:20, 
              length2:0,
            },
            data: [
              // { value: 15, name: "SH&ZJ" },
              // { value: 25, name: "Central" },
              // { value: 10, name: "Great Bay" },
              // { value: 15, name: "North" },
              // { value: 20, name: "Travel Retail" }
            ]
          }
      }
    };
  },

  watch: {
    EchartPieDt(newv, oldv) {
      console.log('pan', this.EchartPieDt,newv, oldv);
      let that = this;
      let arr = [];
      that.EchartPieDt.po.map(item => {
        arr.push({
          value: item.po,
          name: item.area_name
        });
      });
      that.option.series.data = arr;
      // that.myChart.clear()
      that.myChart.setOption(that.option, true);
      
    },
    styles(newv, oldv){
      let that = this
      console.log(newv)
      if (newv) {
        that.option.legend.top = newv.legend.top
        that.option.legend.right = newv.legend.right
        that.option.legend.orient = newv.legend.orient
        that.option.legend.itemHeight = newv.legend.itemHeight;
        that.option.legend.itemWidth = newv.legend.itemWidth;
        that.option.legend.textStyle.fontSize = newv.legend.textStyle.fontSize
        that.option.legend['itemGap'] = newv.legend.itemGap
        that.option.series.left = newv.series.left
        that.option.series.radius= newv.series.radius
        that.option.series.top= newv.series.top
        that.option.series.label.fontSize = newv.series.label.fontSize;
      }

      that.myChart.setOption(that.option);
    }
  },
  mounted() {
    let that = this;
    let echartDom = document.getElementById("EchartPie");
    that.myChart = that.$echarts.init(echartDom,null, { renderer: 'svg' });
    that.myChart.setOption(that.option);
    // let screenWidth = document.body.clientWidth
    // this.option.legend.itemWidth = this.option.legend.itemHeight = (this.option.legend.itemWidth*screenWidth)/1920
    // this.option.legend.textStyle.fontSize = (this.option.legend.textStyle.fontSize*screenWidth)/1920
    // this.option.series[0].label.fontSize =(this.option.series[0].label.fontSize*screenWidth)/1920
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.echartBox{
  position: relative;
}
#EchartPie {
  width: calc(444px*2);
  height: calc(216px*2);
  margin: 0 auto;
}

.run {
  transform: translateY(calc(-25px*2));
  transition: transform ease-out 0.3s;
}
</style>
