<template>
  <div>
    <div class="toptip">
      <svg height="4535" viewBox="0 0 4535 4535" width="4535" xmlns="http://www.w3.org/2000/svg">
        <path class="cls-1" d="M-267.686-1662.46L5902.58,4507.81l-678.67,678.67L-946.359-983.789Z" data-name="矩形 1"
          id="矩形_1" />
        <text class="cls-2" data-name="WORKING IN THE PROGRESS" id="WORKING_IN_THE_PROGRESS"
          transform="translate(2520.426 2006.384) rotate(45) scale(2.464)">
          <tspan x="0">WORKING IN THE PROGRESS</tspan>
        </text>
      </svg>
    </div>
    <heaDer></heaDer>
    <div class="main">
      <div class="section-mind">
        <div class="piece">
          <h2>
            <p>Boutique Ranking</p>
            <span class="time">TILL TODAY {{ tilltoday }}</span>
          </h2>
          <div class="card">
            <div :class="card.rightCard2.index == 0 ? 'active' : ''" class="box just">
              <div class="table-tt">MTD Entry Traffic by Boutique</div>
              <MtaBle :taBleDt="taBleDt3" :tableIndex="card.rightCard2.index + ''" @getScrollend="getScrollend"></MtaBle>
            </div>
            <div :class="card.rightCard2.index == 1 ? 'active' : ''" class="box back">
              <div class="table-tt">MTD Stay Length by Boutique</div>
              <MtaBle :taBleDt="taBleDt1"></MtaBle>
            </div>
            <div class="btns">
              <div :class="card.rightCard2.index == 1 ? '' : 'active'" @click="btnclick" class="btn" data-index="0"
                data-name="rightCard2">
                <span>Traffic</span>
              </div>
              <div :class="card.rightCard2.index == 1 ? 'active' : ''" @click="btnclick" class="btn" data-index="1"
                data-name="rightCard2">
                <span>Stay Length</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-left">
        <div class="section-tt">
          <h2>ENTRY TRAFFIC OVERVIEW</h2>
          <span class="time">TILL TODAY {{ tilltoday }}</span>
        </div>
        <div class="box key-index">
          <div class="title">Traffic Key Index</div>
          <keyIndex :keyIndexDt="keyIndexDt"></keyIndex>
        </div>
        <div class="box box-epie">
          <div class="title">MTD Entry Traffic Proportion of Regions</div>
          <EchartPie :EchartPieDt="EchartPieDt"></EchartPie>
        </div>
        <div class="card">
          <div :class="card.stayLength.index == 0 ? 'active' : ''" class="box box-table just">
            <!-- <div style="display:flex;align-items: center;line-height: 1;">
              <h2>ENTRY TRAFFIC</h2>
              <span class="time">TILL TODAY {{tilltoday}}</span>
            </div>
            <div class="length">
              <div>MTD Entry Traffic</div>
              <div style="display:flex;align-items: center;font-size: 20px;font-family: BrilliantCut-Bold;">
                <div class="numb">
                  <div :class="traffic.old!=traffic.new?'up':''">
                    <p>{{traffic.old}}</p>
                    <p>{{traffic.new}}</p>
                  </div>
                </div>
                <div>k</div>
              </div>
            </div>-->
            <div class="table-tt">
              <!-- <span>Top</span>
              <div class="numb">
                <div>
                  <p>3</p>
                  <p>4</p>
                </div>
              </div>-->
              <span>MTD Entry Traffic by Region</span>
            </div>
            <MtaBle :taBleDt="taBleDt2"></MtaBle>
          </div>
          <div :class="card.stayLength.index == 1 ? 'active' : ''" class="box box-table back">
            <!-- <div style="display:flex;align-items: center;line-height: 1;">
              <h2>STAY LENGTH</h2>
              <span class="time">TILL TODAY {{tilltoday}}</span>
            </div>
            <div class="length">
              <div>MTD Average Stay Length</div>
              <div style="display:flex;align-items: center;font-size: 20px;font-family: BrilliantCut-Bold;">
                <div class="numb">
                  <div :class="stay.old!=stay.new?'up':''">
                    <p>{{stay.old}}</p>
                    <p>{{stay.new}}</p>
                  </div>
                </div>
                <div>min</div>
              </div>
            </div>-->
            <div class="table-tt">
              <!-- <span>Top</span>
              <div class="numb">
                <div>
                  <p>5</p>
                  <p>5</p>
                </div>
              </div>-->
              <span>MTD Stay Length by Region</span>
            </div>
            <MtaBle :taBleDt="taBleDt0"></MtaBle>
          </div>
          <div class="btns">
            <div :class="card.stayLength.index == 1 ? '' : 'active'" @click="btnclick" class="btn" data-index="0"
              data-name="stayLength">
              <span>Traffic</span>
            </div>
            <div :class="card.stayLength.index == 1 ? 'active' : ''" @click="btnclick" class="btn" data-index="1"
              data-name="stayLength">
              <span>Stay Length</span>
            </div>
          </div>
        </div>
        <!-- <div class="box box-ebar">
        <h2 style="padding-top:27px">MTD Standard Deviation</h2>
        <EchartBar :EchartBarDt="EchartBarDt"></EchartBar>

        </div>-->
      </div>

      <div class="section-right">
        <div class="top">
          <!--rightCard1--->
          <div class="piece">
            <h2>
              <span style="margin-top:-32px">ENTRY TRAFFIC HISTORICAL DATA</span>
            </h2>
            <div class="card">
              <div :class="card.rightCard1.index == 0 ? 'active' : ''" class="box just">
                <div class="line-tt">Rolling 7-Day Traffic Comparision</div>
                <div class="roll-num-box">
                  <div class="roll-num-content-box">
                    <div class="roll-num-content-left-box">
                      <div class="roll-num-content-title-box">Avg Weekday Traffic (7 Days)</div>
                      <div class="roll-num-content-text-box">{{ EchartLineDt0.rest_number }}</div>
                    </div>
                    <div class="roll-num-content-right-box">
                      <div class="roll-num-content-title-box">Avg Weekend Traffic(7 Days)</div>
                      <div class="roll-num-content-text-box">{{ EchartLineDt0.working_number }}</div>
                    </div>
                  </div>
                </div>
                <EchartLine :EchartLineDt="EchartLineDt0"></EchartLine>
              </div>
              <!-- <div class="box back" :class="card.rightCard1.index==1?'active':''">
                <div class="line-tt">WTD Entry Traffic in last 4 Weeks</div>
                <EchartLine :EchartLineDt="EchartLineDt1"></EchartLine>
              </div>
              <div class="box just" :class="card.rightCard1.index==2?'active':''">
                <div class="line-tt">Entry Traffic in last 12 Months</div>
                <EchartLine :EchartLineDt="EchartLineDt2"></EchartLine>
              </div>-->
              <!-- <div class="btns">
                <div class="btn" @click="btnclick" data-index="0" data-name="rightCard1" :class="card.rightCard1.index==0?'active':''"><span>Daily Entry Traffic</span></div>
                <div class="btn" @click="btnclick" data-index="1" data-name="rightCard1" :class="card.rightCard1.index==1?'active':''"><span>WTD Entry Traffic</span></div>
                <div class="btn" @click="btnclick" data-index="2" data-name="rightCard1" :class="card.rightCard1.index==2?'active':''"><span>Monthly Entry Traffic</span></div>
              </div>-->
            </div>
          </div>
          <!--rightCard2--->
        </div>
        <div class="bottom">
          <div class="piece">
            <!--<h2>Traffic Trend</h2>-->
            <div>
              <!--rightCard3--->
              <div class="card">
                <!-- <div class="box just" :class="card.rightCard3.index==0?'active':''">
                  <div class="table-tt">Entry Traffic Trend MoM</div>
                  <EchartBarLine :EchartBarLineDt="EchartBarLineDt0"></EchartBarLine>
                </div>-->
                <div class="box just active">
                  <div class="table-tt">Rolling 12-Month Traffic Comparision</div>
                  <EchartBarLine :EchartBarLineDt="EchartBarLineDt1"></EchartBarLine>
                </div>
                <!-- <div class="btns">
                  <div class="btn" @click="btnclick" data-index="0" data-name="rightCard3" :class="card.rightCard3.index==1?'':'active'"><span>MoM</span></div>
                  <div class="btn" @click="btnclick" data-index="1" data-name="rightCard3" :class="card.rightCard3.index==1?'active':''"><span>YoY</span></div>
                </div>-->
              </div>
              <!--rightCard4--->
              <!-- <div class="card">
                <div class="box" :class="card.rightCard4.index==0?'active':''">
                  <div class="table-tt">Average Stay Length Trend MoM</div>
                  <EchartBarLine :EchartBarLineDt="EchartBarLineDt2"></EchartBarLine>
                </div>
                <div class="btns">
                  <div class="btn" @click="btnclick" data-index="0" data-name="rightCard4" :class="card.rightCard4.index==1?'':'active'"><span>MoM</span></div>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>*WTD: Week to Date</p>
      <p>*MTD: Month to Date</p>
    </div>
  </div>
</template>
<script>
import EchartPie from "@/components/EchartPie.vue";
import heaDer from "@/components/heaDer.vue";
import MtaBle from "@/components/taBle.vue";
// import EchartBar from "@/components/EchartBar.vue";
import EchartLine from "@/components/EchartLine.vue";
import EchartBarLine from "@/components/EchartBarLine.vue";
import keyIndex from "@/components/keyIndex.vue";

export default {
  name: "App",
  components: {
    heaDer,
    EchartPie,
    // EchartBar,
    EchartBarLine,
    EchartLine,
    MtaBle,
    keyIndex
  },
  data() {
    return {
      traffic: {
        new: null,
        old: null
      },
      stay: {
        new: null,
        old: null
      },
      tilltoday: "12:00",
      EchartBarDt: {},
      EchartPieDt: {},
      EchartLineDt0: {
        id: 0
      },
      EchartLineDt1: {
        id: 1
      },
      EchartLineDt2: {
        id: 2
      },
      taBleDt0: {
        id: 0,
        data: {}
      },
      taBleDt1: {
        id: 1,
        data: {}
      },
      taBleDt2: {
        id: 2,
        data: {}
      },
      taBleDt3: {
        id: 3,
        data: {}
      },
      EchartBarLineDt0: {
        id: 0,
        rest_number: 0,
        working_number: 0
      },
      EchartBarLineDt1: {
        id: 1
      },
      EchartBarLineDt2: {
        id: 2
      },
      keyIndexDt: {
        mtd: null,
        stay: null,
        Today: null,
        WTD: null,
        YoY: null
      },
      card: {
        stayLength: {
          index: 0
        },
        rightCard1: {
          index: 0
        },
        rightCard2: {
          index: 0
        },
        rightCard3: {
          index: 0
        },
        rightCard4: {
          index: 0
        }
      },
      token: null,
      setInterval: null
    };
  },
  methods: {
    getScrollend(e) {
      if (e == "end") {
        this.card.rightCard2.index = this.card.rightCard2.index == 0 ? 1 : 0;
      }
    },
    btnclick(e) {
      let dt = e.target.dataset;
      this.card[dt.name].index = dt.index;
    },
    request(url) {
      let that = this;
      let code = navigator.userAgent;

      //code = 'dfgdfgf'
      console.log("token2222", that.token);
      return new Promise((resolve, reject) => {
        that
          .$axios({
            url: url,
            method: "get",
            headers: {
              Authorization: "Bearer " + that.token
            }
          })
          .then((res) => {
            if (res.data.code == 401) {
              console.log("0000000");
              if (code.indexOf("Mozilla") > -1) {
                that.$router.push({ name: "Login" });
              } else {
                that.$router.push({ name: "tvLogin" });
              }
            } else {
              resolve(res);
            }
          })
          .catch((res) => {
            reject(res);
          });
      });
    },
    async getTeto() {
      // 获取今日流量情况
      let that = this;
      that.request(that.$api.commonUrl + "/td/teto").then((res) => {
        console.log("!!!!今日流量情况", res);
        if (res.data.code == 200) {
          let t = res.data.data.time.split(" ")[1].split(":");
          that.EchartPieDt = res.data.data;
          that.tilltoday = t[0] + ":" + t[1];

          that.keyIndexDt = {
            // stay: res.data.data.avgTime,
            Today: res.data.data.today,
            WTD: res.data.data.week,
            mtd: res.data.data.month,
            YoY: res.data.data.yoy
          };
        }
      });
    },
    async getentryDay() {
      // 获取时间段流量情况 最近7天
      let that = this;
      that
        // .request(that.$api.commonUrl + "/traffic/entry?type=day")
        .request(that.$api.commonUrl + "/traffic/entry_day")

      that
        .request(that.$api.commonUrl + "/traffic//entry_day_now")
        .then((res) => {
          //console.log("!!!!时间段流量情况 最近7天", res);
          let data = res.data.data
          console.log(data)
          if (res.data.code == 200) {
            that.EchartLineDt0 = {
              id: 0,
              type: "day_now",
              list: {
                name: ["Current Week Traffic", "Last Week Traffic"],
                data: [data.this_week, data.last_week]
              },
              rest_number: data.this_week_rest,
              working_number: data.this_week_working,
            };
          }
        });
    },
    async getentryWeek() {
      // 获取时间段流量情况 最近一个月
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/entry?type=week")
        .then((res) => {
          // console.log("!!!!时间段流量情况 最近一个月", res);
          if (res.data.code == 200) {
            that.EchartLineDt1 = {
              id: 1,
              type: "week",
              list: res.data.data
            };
          }
        });
    },
    async getentryMonth() {
      // 获取时间段流量情况 最近12月
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/entry?type=month")
        .then((res) => {
          // console.log("!!!!时间段流量情况 最近12月", res);
          if (res.data.code == 200) {
            that.EchartLineDt2 = {
              id: 2,
              type: "month",
              list: res.data.data
            };
          }
        });
    },
    async getRankArea() {
      // 获取时间段流量排名
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/rank?type=area")
        .then((res) => {
          // console.log("!!!!时间段流量排名", res);
          if (res.data.code == 200) {
            let data = [];
            res.data.data.map((it, i) => {
              data.push({
                rank: i + 1,
                region: it.area_name,
                length: it.people_count,

                mom: it.yoy,
                isnew: it.is_new
              });
            });
            let ls = [];
            if (data.length > 24) {
              ls[0] = data.slice(0, 24);
              ls[1] = data.slice(24);
            } else {
              ls[0] = data;
            }
            that.taBleDt2 = {
              id: 2,
              type: "area",
              data: ls,
              lab: ["Ranking", "Region", "Traffic(K)"]
            };
          }
        });
    },
    async getRankStore() {
      // 获取时间段流量排名
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/rank?type=store")
        .then((res) => {
          console.log("!!!!时间段流量排名TAB3", res);
          if (res.data.code == 200) {
            that.mmm++;
            let data = [];
            res.data.data.map((it, i) => {
              data.push({
                rank: i + 1,
                region: it.store_name,
                length: it.people_count,
                // rank: it.last_rank,
                mom: it.yoy,
                isnew: it.is_new,
                status: it.rank_statue
              });
            });
            let ls = [];
            if (data.length > 24) {
              ls[0] = data.slice(0, 24);
              ls[1] = data.slice(24);
            } else {
              ls[0] = data;
            }
            that.taBleDt3 = {
              id: 3,
              type: "store",
              data: ls,
              lab: ["Ranking", "Boutique", "Traffic(K)"]
            };
          }
        });
    },
    async getTrendMom() {
      //获取6个月时间流量环比
      let that = this;
      that.request(that.$api.commonUrl + "/traffic/trend/mom").then((res) => {
        // console.log("!!!!6个月时间流量环比", res);
        let data = res.data.data;
        //   console.log(data)
        if (res.data.code == 200) {
          that.EchartBarLineDt0 = {
            id: 0,
            data: data
          };
        }
      });
    },
    async getTrendYoy() {
      //获取6个月时间流量环比
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/trend/last_year")
        .then((res) => {
          let data = res.data.data;
          let obj = [];
          if (data.last_date) {
            for (let i = 0; i < data.last_date.length; i++) {
              const lastItem = data.last_date[i];
              const thisItem = data.this_date[i] || { people: 0 };
              obj.push({
                ...lastItem,
                thisPeople: thisItem.people,
                lastPeople: lastItem.people
              });
            }
          }
          that.EchartBarLineDt1 = {
            id: 1,
            data: obj,
            thisDate: data.this_year,
            lastDate: data.last_year
          };
        });
      // that.request(that.$api.commonUrl + "/traffic/trend/yoy").then((res) => {
      //   // console.log("!!!!6个月时间流量环比yoy", res);
      //   if (res.data.code == 200) {
      //     that.EchartBarLineDt1 = {
      //       id: 1,
      //       data: res.data.data
      //     };
      //   }
      // });
    },
    async getTrendAvg() {
      //获取6个月时间流量平均停留时长等比
      let that = this;
      that.request(that.$api.commonUrl + "/traffic/trend/avg").then((res) => {
        // console.log("!!!!6个月时间流量平均停留时长等比", res);
        if (res.data.code == 200) {
          that.EchartBarLineDt2 = {
            id: 2,
            data: res.data.data
          };
        }
      });
    },
    async getTrendStay() {
      //获取本月停留时长
      let that = this;
      that.request(that.$api.commonUrl + "/stay/mtd").then((res) => {
        // console.log("!!!!本月停留时长", res);
        if (res.data.code == 200) {
          that.keyIndexDt.stay = res.data.data.avgTime;
          // that.stay.new = res.data.data.avgTime;
          // if (!that.stay.old) {
          //   that.stay.old = that.stay.new;
          // } else {
          //   setTimeout(function() {
          //     that.stay.old = that.stay.new;
          //   }, 1000);
          // }
        }
      });
    },
    async getTrendStayRankArea() {
      //停留时长排名
      let that = this;
      that.request(that.$api.commonUrl + "/stay/rank?type=area").then((res) => {
        console.log("!!!!停留时长排名taBleDt0", res);
        if (res.data.code == 200) {
          let data = [];
          res.data.data.map((it, i) => {
            data.push({
              rank: i + 1,
              region: it.area_name,
              length: it.avg_time,
              // rank: it.last_rank,
              mom: it.mom,
              isnew: it.is_new
            });
          });
          let ls = [];
          if (data.length > 24) {
            ls[0] = data.slice(0, 24);
            ls[1] = data.slice(24);
          } else {
            ls[0] = data;
          }
          that.taBleDt0 = {
            id: 0,
            type: "area",
            data: ls,
            lab: ["Ranking", "Region", "Length(min)"]
          };
        }
      });
    },
    async getTrendStayRankStore() {
      //停留时长排名
      let that = this;
      that
        .request(that.$api.commonUrl + "/stay/rank?type=store")
        .then((res) => {
          // console.log("!!!!停留时长排名", res);
          if (res.data.code == 200) {
            let data = [];
            res.data.data.map((it, i) => {
              data.push({
                rank: i + 1,
                region: it.store_name,
                length: it.avg_time,
                // rank: it.last_rank,
                mom: it.mom,
                isnew: it.is_new,
                status: it.rank_statue
              });
            });
            let ls = [];
            if (data.length > 24) {
              ls[0] = data.slice(0, 24);
              ls[1] = data.slice(24);
            } else {
              ls[0] = data;
            }
            that.taBleDt1 = {
              id: 1,
              type: "area",
              data: ls,
              lab: ["Ranking", "Boutique", "Length<br/>(min)"]
            };
          }
        });
    },
    async getTrendStandard() {
      //方差
      let that = this;
      that.request(that.$api.commonUrl + "/standard/mtd").then((res) => {
        // console.log("!!!!方差", res);
        if (res.data.code == 200) {
          that.EchartBarDt = res.data.data;
        }
      });
    },
    getData() {
      let that = this;
      that.getTeto();
      that.getentryDay();
      that.getRankArea();
      that.getRankStore();
      that.getTrendMom();
      that.getTrendYoy();
      that.getTrendAvg();
      that.getTrendStayRankArea();
      that.getTrendStayRankStore();
      that.getTrendStandard();

      // that.getentryWeek();
      // that.getentryMonth();
      // that.getTrendStay();

    }
  },
  mounted() {
    document.getElementById("app").style.transformOrigin = "left top";
    let that = this;
    let code = navigator.userAgent;
    //code = 'dfgdfgf'
    that.token = window.localStorage.getItem("cart-token");

    if (!that.token) {
      if (code.indexOf("Mozilla") > -1) {
        that.$router.push({ name: "Login" });
      } else {
        that.$router.push({ name: "tvLogin" });
      }
      return;
    }

    if (that.token == "pc-notoken") {
      setInterval(function () {
        console.log("...");
        for (let k in that.card) {
          if (k == "rightCard1") {
            // if (that.card[k].index < 2) {
            //   that.card[k].index++;
            // } else {
            //   that.card[k].index = 0;
            // }
          } else {
            if (that.card[k].index < 1) {
              that.card[k].index++;
            } else {
              that.card[k].index = 0;
            }
          }
        }
      }, 60000);
    }
    that.getData();
    that.setInterval = setInterval(function () {
      that.getData();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.setInterval);
  }
};
</script>
<style lang="scss" scoped>
.roll-num-box {
  font-size: calc(16px * 2);
  font-family: "BrilliantCut-Medium";
}

.roll-num-content-box {
  display: flex;
  background-color: rgb(232, 232, 232);
  /* padding: calc(16px * 2); */
  margin: calc(16px * 2);
  border-radius: calc(14px * 2);
}

.roll-num-content-left-box {
  padding: calc(16px * 2);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #fff;
}

.roll-num-content-right-box {
  padding: calc(16px * 2);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.roll-num-content-title-box {
  color: rgba(128, 128, 128, 1);
  font-size: calc(14px * 2);
  margin-bottom: calc(8px * 2);
}

.roll-num-content-text-box {
  font-size: calc(28px * 2);
  font-weight: bold;

}

.main {
  display: flex;
  justify-content: center;
}

h2 {
  font-size: calc(20px * 2);
  text-transform: uppercase;
  font-family: BrilliantCut-Bold;
  font-weight: 400;
}

.time {
  font-family: BrilliantCut-Bold;
  font-size: calc(13px * 2);
  color: #969696;
}

.box {
  overflow: hidden;
  border-radius: calc(15px * 2);
  padding: 0 calc(25px * 2);
  box-sizing: border-box;
}

.section-left {
  width: calc(494px * 2);
}

.section-left .box {
  width: 100%;
  background: #f7e9e9;
  margin-bottom: calc(6px * 2);
}

.section-left .box>.title {
  font-size: calc(16px * 2);
  font-family: BrilliantCut-Medium;
}

.section-left .box-epie {
  height: calc(264px * 2);
}

.section-left .box-epie>.title {
  margin-top: calc(25px * 2);
}

.section-right {
  position: relative;
  width: calc(670px * 2);
}

/* h2 {
  font-weight: bold;
} */
.box-epie h2 {
  font-size: calc(20px * 2);
  margin-top: calc(20px * 2);
  margin-bottom: calc(4px * 2);
}

.box-table .time {
  margin-left: calc(50px * 2);
}

.box-table {
  height: 100%;
  padding: calc(25px * 2) calc(35px * 2) !important;
}

.box-table .length {
  height: calc(28px * 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5dcdc;
  font-size: calc(14px * 2);
  padding: 0 calc(30px * 2) 0 calc(9px * 2);
  margin: calc(12px * 2) 0 calc(15px * 2) 0;
  font-family: BrilliantCut-Regular;
}

.box-table .length .numb {
  height: calc(26px * 2);
  line-height: calc(26px * 2);
  overflow: hidden;
  margin-right: calc(6px * 2);
}

.table-tt {
  display: flex;
  align-items: center;
  font-size: calc(16px * 2);
  font-family: BrilliantCut-Medium;
}

.table-tt .numb {
  height: calc(20px * 2);
  margin: 0 calc(4px * 2);
  overflow: hidden;
  line-height: calc(22px * 2);
}

.section-right .table-tt {
  margin-top: calc(26px * 2);
  margin-bottom: calc(16px * 2);
}

.card {
  position: relative;
  width: 100%;
  height: calc(385px * 2);
  -webkit-perspective: 1500;
  perspective: 1500;
}

.section-left .card {
  height: calc(311px * 2);
}

.card .box {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.card .just {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.card .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card .box.active {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.card .btns {
  position: absolute;
  width: 100%;
  bottom: calc(14px * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: BrilliantCut-Regular;
}

.card .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(146px * 2);
  height: calc(20px * 2);
  font-size: calc(10px * 2);
  color: #000;
  margin: 0 calc(5px * 2);
  border: calc(1px * 2) solid #000;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.card .btn span {
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.card .btn::before {
  content: "";
  position: absolute;
  width: calc(150px * 2);
  height: calc(24px * 2);
  background-color: #000;
  top: 0;
  left: 0;
  transform: translateY(calc(28px * 2));
  transition: transform 0.3s ease-in-out;
}

.card .btn.active,
.card .btn:hover {
  color: #fff;
  transition: color 0.3s ease-in-out;
  border: none;
}

.card .btn.active::before,
.card .btn:hover::before {
  transform: translateY(calc(-2px * 2));
  transition: transform 0.3s ease-in-out;
}

.piece>h2,
.section-tt {
  height: calc(64px * 2);

  text-align: left;
  padding: 0 calc(25px * 2);
  background-color: #e8e8e8;
  border-radius: calc(15px * 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-mind .piece>h2,
.section-tt {
  height: calc(64px * 2);
}

.section-right .box {
  background-color: #f5f5f5;
  margin-top: calc(6px * 2);
}

.section-tt {
  margin-bottom: calc(6px * 2);
  background-color: rgba(243, 218, 218, 1);
}

.section-right .top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.section-right .top .piece:first-child,
.section-right .top .piece:first-child .card {
  width: 100%;
}

/* .section-right .top .piece:last-child {
  width: 448px;
} */
.section-right .top .box,
.section-right .card {
  height: calc(468px * 2);
  width: 100%;
}

.section-right .bottom {
  margin-top: calc(6px * 2);
}

.section-right .bottom .piece>div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.section-right .bottom .piece .box {
  width: 100%;
  height: calc(365px * 2);
}

/*.section-right .bottom .card {*/
/*height: 359px;*/
/*}*/
.section-right .bottom .btns {
  bottom: calc(10px * 2);
}

.section-left {
  margin: 0 calc(11px * 2);
}

.section-mind .piece {
  width: calc(658px * 2);
}

.section-mind .piece .card {
  width: 100%;
  height: calc(838px * 2);
  margin-top: calc(6px * 2);
}

.section-mind .box {
  width: 100%;
  padding: 0 calc(20px * 2);
  background-color: rgba(245, 245, 245, 1);
  height: 100%;
}

/* .section-mind .table-tt,
/deep/.section-mind .box .taBle .hd,
/deep/.section-mind .box .taBle .bd {
  padding: 0 5px;
} */
.section-mind .table-tt,
::v-deep(.section-mind .cloum) {
  padding: 0 calc(5px * 2);
}

.section-mind .table-tt {
  padding-top: calc(26px * 2);
  margin-bottom: calc(10px * 2);
}

::v-deep(.section-mind .taBle) {
  display: flex;
}

.line-tt {
  font-size: calc(16px * 2);
  font-family: "BrilliantCut-Medium";
  padding-top: calc(26px * 2);
}

::v-deep(.section-mind .scroll) {
  /* height: 494px; */
  /* background-color: #e3e3e3; */
  height: calc(660px * 2);
  overflow-y: auto;
  border-radius: calc(20px * 2);
}

::v-deep(.section-mind .scroll.istv) {
  overflow: hidden;
}

.key-index {
  height: calc(250px * 2);
  background-color: rgba(247, 233, 233, 1);
}

.key-index .title {
  padding-top: calc(26px * 2);
  margin-bottom: calc(10px * 2);
}

.key-index .group {
  display: flex;
}

.footer {
  display: flex;
  align-items: center;
  font-size: calc(12px * 2);
  padding: 0 calc(80px * 2) calc(20px * 2) calc(40px * 2);
  margin-top: calc(12px * 2);
}

.footer p {
  margin-right: calc(40px * 2);
}

.toptip {
  position: fixed;
  right: 0;
  top: 0;
  height: calc(300px * 2);
  width: calc(300px * 2);
  z-index: 1;
}

.toptip svg {
  width: 100%;
  height: 100%;
}

.cls-1 {
  fill: #a20002;
  fill-rule: evenodd;
}

.cls-2 {
  font-size: 120px;
  fill: #fff;
  text-anchor: middle;
  font-family: "BrilliantCut-Regular";
  font-weight: 700;
}
</style>
