<template>
  <div class="login">
    <img src="../assets/logo@3x.png" class="logo" />
    <h2>Beyond Traffic DASHBOARD</h2>
    <!-- <img :src="ewm" class="ewm" /> -->

    <div class="qrcode" ref="qrCodeUrl">
      <img src="../assets/ewm.png" class="lg" />
    </div>
    <!-- <div>{{msss}}</div> -->


  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  components: {},
  data() {
    return {
      msss: null,
      ewm: null,
      formData: {
        user_name: null,
        password: null,
        code: null,
        a: null
      },
      msg: null,
      keyimg: null,
      submiting: true
    };
  },
  methods: {
    creatQrCode(str) {
      let that = this
      let w = this.$refs.qrCodeUrl.offsetHeight

      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: 'https://beyond-traffic.incker.com/#/Invitation?invitationcode=' + str, // 需要转换为二维码的内容
        width: w - 10,
        height: w - 10,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },

  },
  mounted() {
    document.getElementById('app').style.transformOrigin = 'left 50%'
    let that = this;
    that.formData.a = '' + new Date().getTime()
    let code = navigator.userAgent
    //code = 'dfgdfgf'

    this.creatQrCode(code);
    let vtime = setInterval(function () {
      that.$axios({
        url: that.$api.commonUrl + "/is_bind?apk_code=" + code,
        method: "get"
      })
        .then(res => {
          that.msss = res.data
          if (res.data.code == 200) {
            that.$router.push({ name: "Index" });
            window.localStorage.setItem('cart-token', 'pc-notoken');
            clearInterval(vtime)
          }
        })
    }, 1000)
  }
};
</script>
<style scoped>
input,
button,
textarea {
  font-family: "PingFang";
  padding: 0;
  background: none;
  outline: none;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.8);
}

::-webkit-input-placeholder {
  color: #999;
}

.login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: calc(75px*2);
  width: auto;
  margin-bottom: calc(95px*2);
}

h2 {
  text-align: center;
  font-size: calc(36px*2);
  font-family: "BrilliantCut-Bold";
  margin-bottom: calc(67px*2);
}

.form .line {
  /* display: flex;
  align-items: center; */
  font-size: calc(26px*2);
  margin-bottom: calc(10px*2);
}

.form .line .lb {
  /* width: 90px; */
  /* text-align: justify;
  text-justify: distribute-all-lines;
  text-align-last: justify; */
}

input {
  width: calc(461px*2);
  height: calc(52px*2);
  border: calc(1px*2) solid #000;
  box-sizing: border-box;
  padding: 0 calc(20px*2);
  font-size: calc(27px*2);
}

.submit {
  width: 100%;
  height: calc(52px*2);
  background-color: rgba(56, 56, 56, 1);
  font-size: calc(24px*2);
  color: #fff;
  text-align: center;
  line-height: calc(52px*2);
  margin-top: calc(38px*2);
  cursor: pointer;
}

.line:nth-child(3) input {
  width: calc(294px*2);
}

.line .cont {
  display: flex;
  align-items: center;
}

.line img {
  width: calc(157px*2);
  height: calc(52px*2);
  margin-left: calc(7px*2);
  cursor: pointer;
}

.msg {
  position: fixed;
  width: calc(400px*2);
  height: calc(80px*2);
  text-align: center;
  line-height: calc(80px*2);
  background: #fff;
  font-size: calc(22px*2);
  color: #000;
  box-shadow: 0 0px calc(30px*2) rgb(0 0 0 / 10%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qrcode {
  width: calc(200px*2);
  height: calc(200px*2);
  position: relative;
  border: calc(1px*2) solid #000
}

/deep/.qrcode img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.qrcode .lg {
  width: calc(53px*2);
  height: calc(53px*2);
  z-index: 2;
}

.logintv {
  transform-origin: left 50% !important;
}</style>