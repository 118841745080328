/**接口地址配置 */
let commonUrl = 'https://traffic-d.incker.com/2ZMCH3n2p5T'
let refs = {
    teto: '/td/teto', // 今日流量情况
    entry:'/traffic/entry?type=', // 时间段流量情况；type = day,week,month
    rank: '/traffic/rank?type=', // 时间段流量排名；type = area,store
}
let baseURL = {
    commonUrl,
    refs
}

export default baseURL