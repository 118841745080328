<template>
  <div class="content">
    <div class="statu" v-if="statu">{{ statu }}</div>
    <div class="login" v-if="!statu">
      <img src="@/assets/logo@3x.png" class="logo" />
      <h2>Beyond Traffic DASHBOARD</h2>
      <div class="form">
        <div class="line">
          <div class="lb">Invitation Code</div>
          <div class="cont">
            <input v-model="formData.invite_code" />
          </div>
        </div>
        <div class="line">
          <div class="lb">Remark</div>
          <div class="cont">
            <input v-model="formData.remark" />
          </div>
        </div>
        <div class="submit" @click="submit">Submit</div>
      </div>
      <div class="msg" v-if="msg">{{ msg }}</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      statu: null,
      formData: {
        apk_code: null,
        invite_code: null,
        remark: null
      },
      msg: null,
      keyimg: null,
      submiting: true
    };
  },
  methods: {
    submit() {
      let that = this;
      let isok = true;

      that.msg = "";
      for (let k in that.formData) {
        if (k == 'invite_code' && !that.formData[k]) {
          that.msg = "Wrong Invitation Code";
          setTimeout(function () {
            that.msg = "";
          }, 2000);
          isok = false;
          return;
        }
      }
      if (isok) {
        // console.log(that.formData)
        if (!that.submiting) return;
        that.submiting = false;
        that
          .$axios({
            url: that.$api.commonUrl + "/bind",
            method: "post",
            data: that.formData
          })
          .then(res => {
            console.log(res);
            that.submiting = true;
            if (res.data.code == 200) {
              that.statu = 'Login successful'
            } else {
              that.msg = res.data.msg;
              setTimeout(function () {
                that.msg = "";
              }, 3000);
            }
          })
          .catch(res => {
            that.submiting = true;
          });
      }
    }
  },
  mounted() {
    let that = this;
    that.formData.a = "" + new Date().getTime();
    that.formData.apk_code = that.$util.getParam('invitationcode')
  }
};
</script>
<style scoped>
input,
button,
textarea {
  font-family: "PingFang";
  padding: 0;
  background: none;
  outline: none;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.8);
}

::-webkit-input-placeholder {
  color: #999;
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login {

  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 75px;
  width: auto;
  margin-bottom: 95px;
}

h2 {
  text-align: center;
  font-size: 32px;
  font-family: "BrilliantCut-Bold";
  margin-bottom: 67px;
}

.form .line {
  font-size: 27px;
  margin-bottom: 20px;
}

.form .line .lb {
  /* text-align: justify;
  text-justify: distribute-all-lines;
  text-align-last: justify; */
}

input {
  width: 600px;
  height: 52px;
  border: 1px solid #000;
  box-sizing: border-box;
  padding: 0 20px;
  font-size: 27px;
}

.submit {
  width: 100%;
  height: 52px;
  background-color: rgba(56, 56, 56, 1);
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 52px;
  margin-top: 38px;
  cursor: pointer;
}

.line:nth-child(3) input {
  width: 188px;
}

.line .cont {
  display: flex;
  align-items: center;
}

.line img {
  width: 157px;
  height: 52px;
  margin-left: 7px;
  cursor: pointer;
}

.msg {
  position: fixed;
  width: 400px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  background: #fff;
  font-size: 28px;
  color: #000;
  box-shadow: 0 0px 30px rgb(0 0 0 / 10%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.statu {
  font-size: 36px;
  font-family: "BrilliantCut-Bold";
}</style>