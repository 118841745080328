import { render, staticRenderFns } from "./taBle.vue?vue&type=template&id=ca8c35ec&scoped=true&"
import script from "./taBle.vue?vue&type=script&lang=js&"
export * from "./taBle.vue?vue&type=script&lang=js&"
import style0 from "./taBle.vue?vue&type=style&index=0&id=ca8c35ec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_y5o36p7qrshhm4v7l6yk7rwfsu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca8c35ec",
  null
  
)

export default component.exports