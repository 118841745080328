<template>
  <div>
    <div class="keyindex day">
      <div class="item" v-for="(v,k) in day" :key="k">
        <div class="lb">
          <span>{{k=='mtd'?'MTD Traffic':k=='stay'?'MTD Average Stay Length':k=='YoY'?'MTD Traffic YoY%':k=='WTD'?'WTD Traffic':'Today Traffic'}}</span>
        </div>
        <div class="nb">
          <div :class="oldday&&(oldday[k]!=v)?'run':''">
            <p>
              <span>{{oldday?oldday[k]:''}}</span>
              <span class='min' v-if="k=='stay'">min</span>
              <span style="" v-if="k=='YoY'">%</span>
              <span class="ic" v-if="k=='YoY'" :class="v>=0?'up':''"></span>
            </p>
            <p>
              <span>{{v}}</span>
              <span class='min' v-if="k=='stay'">min</span>
              <span style="" v-if="k=='YoY'">%</span>
              <span class="ic" v-if="k=='YoY'" :class="v>=0?'up':''"></span>
            </p>
          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "keyIndex",
  props: {
    keyIndexDt: {
      type: Object,
      deep: true
    },
    styles: {
      type: Object
    }
  },

  data() {
    return {
      myChart: null,
      oldday: null,
      day: {
        // stay:'0',
        Today: "0",
        WTD: "0",
        mtd: "0",
        YoY: "0"
      }
    };
  },

  watch: {
    keyIndexDt(newv, oldv) {
      console.log("123445");
      console.log("KEY", this.keyIndexDt, newv, oldv);
      let that = this;
      let arr = [];
      that.day = that.keyIndexDt;

      setTimeout(function() {
        that.oldday = newv;
      }, 1000);
    }
  },
  mounted() {
    let that = this;

    that.oldday = that.day;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.day {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-family: BrilliantCut-Regular;
  background: rgba(243, 218, 218, 1);
  border-radius: calc(10px*2);
  position: relative;
}
.day:before,
.day::after {
  position: absolute;
  content: "";
  background-color: rgba(247, 233, 233, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.day:before {
  width: 100%;
  height: calc(2px*2);
  /* margin-top: 14px; */
}
.day::after {
  height: 100%;
  width: calc(2px*2);
}
.item {
  width: calc(221px*2);
  height: calc(86px*2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .item:nth-child(1),
.item:nth-child(2){
  height:98px;
}
.item:nth-child(3),
.item:nth-child(4){
  height:70px;

} */
/* .item:nth-child(3) .nb,
.item:nth-child(4) .nb{
  font-size: 26px;
} */
.item:nth-child(5) {
  position: absolute;
  top: calc(62px*2);
  display: flex;
  flex-direction: initial;
}
.item:nth-child(5) .nb {
  font-size: calc(18px*2);
}
/* .item:nth-child(1) .lb{
  margin-top:-24px;
} */
.day .lb span {
  font-size: calc(12px*2);
  color: #808080;
  /* text-transform: uppercase; */
  position: relative;
}
/* .day .lb span::before,
.day .lb span::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 1px;
  background-color: #808080;
  top: 0;
  bottom: 0;
  margin: auto;
}
.day .lb span::before {
  left: -6px;
}
.day .lb span::after {
  right: -6px;
} */
.day .nb {
  position: relative;
  height: calc(38px*2);
  overflow: hidden;
  font-size: calc(30px*2);
  display: flex;
  align-items: baseline;
  margin-top: calc(2px*2);
}
.day .nb .min {
  font-size: calc(20px*2);
  margin-left: calc(10px*2);
}
.day p {
  height: calc(38px*2);
  line-height: calc(38px*2); 
}
.day p span{
  display: inline-block;
  vertical-align: middle;
}
.run {
  transform: translateY(calc(-38px*2));
  transition: transform ease-out 0.3s;
}
.ic {
  width: calc(14px*2);
  height: calc(24px*2);
  margin-left: calc(6px*2);
  background: url(../assets/icons.png) no-repeat;
  background-size: 100% auto;
  background-position: 0 0;
  transition: background linear 0.3s;
}
.ic.up {
  background-position: 0 calc(-24px*2);
  transition: background linear 0.3s;
}
</style>
