<template>
  <div>
    <heaDer></heaDer>
    <div class="section-left">
      <div class="box box-epie">
        <h2>TODAY'S ENTRY TRAFFIC OVERVIEW</h2>
        <span class="time">TILL TODAY {{ tilltoday }}</span>
        <div class="title">Entry Traffic & MTD Entry Traffic Proportion of Regions</div>
        <EchartPie :EchartPieDt="EchartPieDt" :styles="stylesPie"></EchartPie>
      </div>
      <div class="card" data-type="stayLength" @touchstart='touchStart' @touchend='touchEnd'>
        <div class="box box-table just" :class="card.stayLength.index == 0 ? 'active' : ''">
          <div style="display:flex;align-items: center;line-height: 1;">
            <h2>STAY LENGTH</h2>
            <span class="time">TILL TODAY {{ tilltoday }}</span>
          </div>
          <div class="length">
            <div>MTD Entry Traffic</div>
            <div style="display:flex;align-items: center;font-size: 24px;font-family: BrilliantCut-Bold;">
              <div class="numb">
                <div :class="traffic.old != traffic.new ? 'up' : ''">
                  <p>{{ traffic.old }}</p>
                  <p>{{ traffic.new }}</p>
                </div>
              </div>
              <div>k</div>
            </div>
          </div>
          <div class="table-tt">
            <!-- <span>Top</span>
            <div class="numb">
              <div>
                <p>3</p>
                <p>4</p>
              </div>
            </div> -->
            <span>MTD Entry Traffic by Region</span>
          </div>
          <MtaBle :taBleDt="taBleDt2"></MtaBle>
        </div>
        <div class="box box-table back" :class="card.stayLength.index == 1 ? 'active' : ''">
          <div style="display:flex;align-items: center;line-height: 1;">
            <h2>STAY LENGTH</h2>
            <span class="time">TILL TODAY {{ tilltoday }}</span>
          </div>
          <div class="length">
            <div>MTD Average Stay Length</div>
            <div style="display:flex; align-items: center; font-size: 20px; font-family: BrilliantCut-Bold;">
              <div class="numb">
                <div :class="stay.old != stay.new ? 'up' : ''">
                  <p>{{ stay.old }}</p>
                  <p>{{ stay.new }}</p>
                </div>
              </div>
              <div>min</div>
            </div>
          </div>
          <div class="table-tt">
            <!-- <span>Top</span>
            <div class="numb">
              <div>
                <p>5</p>
                <p>5</p>
              </div>
            </div> -->
            <span>MTD Average Stay Length by Region</span>
          </div>
          <MtaBle :taBleDt="taBleDt0"></MtaBle>
        </div>

        <div class="btns">
          <div class="btn" @click="btnclick" data-index="0" data-name="stayLength"
            :class="card.stayLength.index == 0 ? 'active' : ''"><span>Traffic</span></div>
          <div class="btn" @click="btnclick" data-index="1" data-name="stayLength"
            :class="card.stayLength.index == 1 ? 'active' : ''"><span>Stay Length</span></div>
        </div>
      </div>
      <!-- <div class="box box-ebar" style="padding-bottom:50px;">
        <h2 style="padding-top:70px;margin-bottom: 70px;">MTD Standard Deviation</h2>
        <EchartBar :EchartBarDt="EchartBarDt" :styles="stylesBar"></EchartBar>

      </div> -->
    </div>
    <div class="section-right">
      <div class="top">
        <!--rightCard2--->
        <div class="piece">
          <h2 style="height: auto;line-height: 1;text-align: left;padding: 28px 45px;">
            Boutique Ranking
            <span class="time" style="display:block;margin-top: 6px;">TILL TODAY {{ tilltoday }}</span>
          </h2>
          <div class="card" data-type="rightCard2" @touchstart='touchStart' @touchend='touchEnd'>
            <div class="box just" :class="card.rightCard2.index == 0 ? 'active' : ''">
              <div class="table-tt">Top 20 MTD Entry Traffic by Boutique</div>
              <MtaBle :taBleDt="taBleDt3"></MtaBle>
            </div>
            <div class="box back" :class="card.rightCard2.index == 1 ? 'active' : ''">
              <div class="table-tt">Top 20 MTD Average Stay Length by Boutique</div>
              <MtaBle :taBleDt="taBleDt1"></MtaBle>
            </div>
            <div class="btns">
              <div class="btn" @click="btnclick" data-index="0" data-name="rightCard2"
                :class="card.rightCard2.index == 0 ? 'active' : ''"><span>Traffic</span></div>
              <div class="btn" @click="btnclick" data-index="1" data-name="rightCard2"
                :class="card.rightCard2.index == 1 ? 'active' : ''"><span>Stay Length</span></div>
            </div>
          </div>
        </div>
        <!--rightCard1--->
        <div class="piece">
          <h2>ENTRY TRAFFIC OVERVIEW</h2>
          <div class="card" data-type="rightCard1" @touchstart='touchStart' @touchend='touchEnd'>
            <div class="box just" :class="card.rightCard1.index == 0 ? 'active' : ''">
              <div class="line-tt">Entry Traffic in last 7 Days</div>
              <EchartLine :EchartLineDt="EchartLineDt0" :styles="styleLine"></EchartLine>
            </div>
            <div class="box back" :class="card.rightCard1.index == 1 ? 'active' : ''">
              <div class="line-tt">WTD Entry Traffic in last 4 Weeks</div>
              <EchartLine :EchartLineDt="EchartLineDt1" :styles="styleLine"></EchartLine>
            </div>
            <div class="box just" :class="card.rightCard1.index == 2 ? 'active' : ''">
              <div class="line-tt">Entry Traffic in last 12 Months</div>
              <EchartLine :EchartLineDt="EchartLineDt2" :styles="styleLine"></EchartLine>
            </div>
            <div class="btns" style="width:auto;left:45px;right:45px;">
              <div class="btn" @click="btnclick" data-index="0" data-name="rightCard1"
                :class="card.rightCard1.index == 0 ? 'active' : ''"><span>Daily Entry Traffic</span></div>
              <div class="btn" @click="btnclick" data-index="1" data-name="rightCard1"
                :class="card.rightCard1.index == 1 ? 'active' : ''"><span>WTD Entry Traffic</span></div>
              <div class="btn" @click="btnclick" data-index="2" data-name="rightCard1"
                :class="card.rightCard1.index == 2 ? 'active' : ''"><span>Monthly Entry Traffic</span></div>
            </div>
          </div>
        </div>

      </div>
      <div class="bottom">
        <div class="piece">
          <h2>Traffic Trend</h2>
          <div>
            <!--rightCard3--->
            <div class="card" data-type="rightCard3" @touchstart='touchStart' @touchend='touchEnd'>
              <div class="box just" :class="card.rightCard3.index == 0 ? 'active' : ''">
                <div class="table-tt">Entry Traffic Trend MoM</div>
                <EchartBarLine :EchartBarLineDt="EchartBarLineDt0" :styles="styleBarLine"></EchartBarLine>
              </div>
              <div class="box back" :class="card.rightCard3.index == 1 ? 'active' : ''">
                <div class="table-tt">Entry Traffic Trend YoY</div>
                <EchartBarLine :EchartBarLineDt="EchartBarLineDt1" :styles="styleBarLine"></EchartBarLine>
              </div>
              <div class="btns">
                <div class="btn" @click="btnclick" data-index="0" data-name="rightCard3"
                  :class="card.rightCard3.index == 0 ? 'active' : ''"><span>MoM</span></div>
                <div class="btn" @click="btnclick" data-index="1" data-name="rightCard3"
                  :class="card.rightCard3.index == 1 ? 'active' : ''"><span>YoY</span></div>
              </div>
            </div>
            <!--rightCard4--->
            <!-- <div class="card" data-type="rightCard4" @touchstart='touchStart' @touchend='touchEnd'>
              <div class="box" :class="card.rightCard4.index==0?'active':''">
                <div class="table-tt">Average Stay Length Trend MoM</div>
                <EchartBarLine :EchartBarLineDt="EchartBarLineDt2" :styles="styleBarLine"></EchartBarLine>
              </div>
              <div class="btns">
                <div class="btn" @click="btnclick" data-index="0" data-name="rightCard4" :class="card.rightCard4.index==1?'':'active'"><span>MoM</span></div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EchartPie from "@/components/EchartPie.vue";
import heaDer from "@/components/heaDer.vue";
import MtaBle from "@/components/taBle.vue";
// import EchartBar from "@/components/EchartBar.vue";
import EchartLine from "@/components/EchartLine.vue";
import EchartBarLine from "@/components/EchartBarLine.vue";

export default {
  name: "App",
  components: {
    heaDer,
    EchartPie,
    // EchartBar,
    EchartBarLine,
    EchartLine,
    MtaBle
  },
  data() {
    return {
      startX: 0, //滑动开始
      endX: 0, //滑动结束
      traffic: {
        new: null,
        old: null
      },
      stay: {
        new: null,
        old: null
      },
      tilltoday: "12:00",
      EchartBarDt: {},
      EchartPieDt: {},
      EchartLineDt0: {
        id: 0
      },
      EchartLineDt1: {
        id: 1
      },
      EchartLineDt2: {
        id: 2
      },
      taBleDt0: {
        id: 0,
        data: {}
      },
      taBleDt1: {
        id: 1,
        data: {}
      },
      taBleDt2: {
        id: 2,
        data: {}
      },
      taBleDt3: {
        id: 3,
        data: {}
      },
      EchartBarLineDt0: {
        id: 0
      },
      EchartBarLineDt1: {
        id: 1
      },
      EchartBarLineDt2: {
        id: 2
      },
      card: {
        stayLength: {
          index: 0,
          ls: 2
        },
        rightCard1: {
          index: 0,
          ls: 3
        },
        rightCard2: {
          index: 0,
          ls: 2
        },
        rightCard3: {
          index: 0,
          ls: 2
        },
        rightCard4: {
          index: 0
        }
      },
      stylesPie: null,
      stylesBar: null,
      styleLine: null,
      styleBarLine: null,
      token: null,
      setInterval: null
    };
  },
  methods: {
    btnclick(e) {
      let that = this
      let dt = e.currentTarget.dataset;
      this.card[dt.name].index = dt.index;
      this.$forceUpdate()
    },
    //滑动开始
    touchStart(e) {
      // 记录初始位置
      this.startX = e.touches[0].clientX;
    },
    //滑动结束
    touchEnd(e) {
      let that = this;
      // 当前滑动的父级元素
      let type = e.currentTarget.dataset.type;
      // console.log(e.currentTarget.dataset.type);
      let parentElement = e.currentTarget.parentElement;
      // 记录结束位置
      this.endX = e.changedTouches[0].clientX;
      // 左滑大于30距离删除出现
      if (this.startX - this.endX > 30) {
        // console.log("左");
        if (
          that.card[type].ls &&
          that.card[type].index != that.card[type].ls - 1
        ) {
          that.card[type].index++;
        }
        this.$forceUpdate()
      }
      // 右滑
      if (this.startX - this.endX < -30) {
        // console.log("右");
        if (that.card[type].index > 0) {
          that.card[type].index--;
        }

        this.$forceUpdate()
      }
      console.log(that.card[type].index)
      this.startX = 0;
      this.endX = 0;
    },

    request(url) {
      let that = this;
      return new Promise((resolve, reject) => {
        that
          .$axios({
            url: url,
            method: "get",
            headers: {
              Authorization: "Bearer " + that.token
            }
          })
          .then(res => {
            if (res.data.code == 401) {
              that.$router.push({ name: "mbLogin" });
            } else {
              resolve(res);
            }
          })
          .catch(res => {
            reject(res);
          });
      });
    },
    async getTeto() {
      // 获取今日流量情况
      let that = this;
      that.request(that.$api.commonUrl + "/td/teto").then(res => {
        // console.log("!!!!今日流量情况", res);
        if (res.data.code == 200) {
          let t = res.data.data.time.split(" ")[1].split(":");
          that.EchartPieDt = res.data.data;
          that.tilltoday = t[0] + ":" + t[1];
          let mot = res.data.data.month / 1000
          that.traffic.new = mot.toFixed(2);
          if (!that.traffic.old) {
            that.traffic.old = that.traffic.new;
          } else {
            setTimeout(function () {
              that.traffic.old = that.traffic.new;
            }, 1000);
          }
        }
      });
    },
    async getentryDay() {
      // 获取时间段流量情况 最近7天
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/entry?type=day")
        .then(res => {
          // console.log("!!!!时间段流量情况 最近7天", res);
          if (res.data.code == 200) {
            that.EchartLineDt0 = {
              id: 0,
              type: "day",
              list: res.data.data
            };
          }
        });
    },
    async getentryWeek() {
      // 获取时间段流量情况 最近一个月
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/entry?type=week")
        .then(res => {
          // console.log("!!!!时间段流量情况 最近一个月", res);
          if (res.data.code == 200) {
            that.EchartLineDt1 = {
              id: 1,
              type: "week",
              list: res.data.data
            };
          }
        });
    },
    async getentryMonth() {
      // 获取时间段流量情况 最近12月
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/entry?type=month")
        .then(res => {
          // console.log("!!!!时间段流量情况 最近12月", res);
          if (res.data.code == 200) {
            that.EchartLineDt2 = {
              id: 2,
              type: "month",
              list: res.data.data
            };
          }
        });
    },
    async getRankArea() {
      // 获取时间段流量排名
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/rank?type=area")
        .then(res => {
          // console.log("!!!!时间段流量排名", res);
          if (res.data.code == 200) {
            let data = [];
            res.data.data.map((it, i) => {
              data.push({
                region: it.area_name,
                length: it.people_count,
                rank: it.last_rank,
                mom: it.mom
              });
            });
            that.taBleDt2 = {
              id: 2,
              type: "area",
              data: data,
              lab: ["Region", "Traffic", "Rank last month", "MoM"]
            };
          }
        });
    },
    async getRankStore() {
      // 获取时间段流量排名
      let that = this;
      that
        .request(that.$api.commonUrl + "/traffic/rank?type=store")
        .then(res => {
          // console.log("!!!!时间段流量排名", res);
          if (res.data.code == 200) {
            let data = [];
            res.data.data.map((it, i) => {
              data.push({
                region: it.store_name,
                length: it.people_count,
                rank: it.rank,
                mom: it.mom
              });
            });
            that.taBleDt3 = {
              id: 3,
              type: "store",
              data: data,
              lab: ["Boutique", "Traffic", "Rank last month", "MoM"]
            };
          }
        });
    },
    async getTrendMom() {
      //获取6个月时间流量环比
      let that = this;
      that.request(that.$api.commonUrl + "/traffic/trend/mom").then(res => {
        // console.log("!!!!6个月时间流量环比", res);
        if (res.data.code == 200) {
          that.EchartBarLineDt0 = {
            id: 0,
            data: res.data.data
          };
        }
      });
    },
    async getTrendYoy() {
      //获取6个月时间流量环比
      let that = this;
      that.request(that.$api.commonUrl + "/traffic/trend/yoy").then(res => {
        console.log("!!!!6个月时间流量环比yoy", res);
        if (res.data.code == 200) {
          that.EchartBarLineDt1 = {
            id: 1,
            data: res.data.data
          };
        }
      });
    },
    async getTrendAvg() {
      //获取6个月时间流量平均停留时长等比
      let that = this;
      that.request(that.$api.commonUrl + "/traffic/trend/avg").then(res => {
        console.log("!!!!6个月时间流量平均停留时长等比", res);
        if (res.data.code == 200) {
          that.EchartBarLineDt2 = {
            id: 2,
            data: res.data.data
          };
        }
      });
    },
    async getTrendStay() {
      //获取本月停留时长
      let that = this;
      that.request(that.$api.commonUrl + "/stay/mtd").then(res => {
        // console.log("!!!!本月停留时长", res);
        if (res.data.code == 200) {
          that.stay.new = res.data.data.avgTime;
          if (!that.stay.old) {
            that.stay.old = that.stay.new;
          } else {
            setTimeout(function () {
              that.stay.old = that.stay.new;
            }, 1000);
          }
        }
      });
    },
    async getTrendStayRankArea() {
      //停留时长排名
      let that = this;
      that.request(that.$api.commonUrl + "/stay/rank?type=area").then(res => {
        // console.log("!!!!停留时长排名", res);
        if (res.data.code == 200) {
          let data = [];
          res.data.data.map((it, i) => {
            data.push({
              region: it.area_name,
              length: it.avg_time,
              rank: it.last_rank,
              mom: it.mom
            });
          });
          that.taBleDt0 = {
            id: 0,
            type: "area",
            data: data,
            lab: ["Region", "Length", "Rank last month", "MoM"]
          };
        }
      });
    },
    async getTrendStayRankStore() {
      //停留时长排名
      let that = this;
      that.request(that.$api.commonUrl + "/stay/rank?type=store").then(res => {
        // console.log("!!!!停留时长排名", res);
        if (res.data.code == 200) {
          let data = [];
          res.data.data.map((it, i) => {
            data.push({
              region: it.store_name,
              length: it.avg_time,
              rank: it.last_rank,
              mom: it.mom
            });
          });
          that.taBleDt1 = {
            id: 1,
            type: "area",
            data: data,
            lab: ["Boutique", "Length", "Rank last month", "MoM"]
          };
        }
      });
    },
    async getTrendStandard() {
      //方差
      let that = this;
      that.request(that.$api.commonUrl + "/standard/mtd").then(res => {
        // console.log("!!!!方差", res);
        if (res.data.code == 200) {
          that.EchartBarDt = res.data.data;
        }
      });
    },
    getData() {
      let that = this;
      that.getTeto();
      that.getentryDay();
      that.getentryWeek();
      that.getentryMonth();
      that.getRankArea();
      that.getRankStore();
      that.getTrendMom();
      that.getTrendYoy();
      that.getTrendAvg();
      that.getTrendStay();
      that.getTrendStayRankArea();
      that.getTrendStayRankStore();
      that.getTrendStandard();
    }
  },
  mounted() {
    let that = this;
    document.getElementById("app").style = "";
    that.token = window.localStorage.getItem("cart-token");
    if (!that.token) {
      that.$router.push({ name: "mbLogin" });
      return;
    }
    that.getData();
    that.setInterval = setInterval(function () {
      that.getData();
    }, 60000);
    (that.stylesPie = {
      legend: {
        top: 0,
        right: "auto",
        left: "auto",
        orient: "horizontal",
        itemWidth: 21,
        itemHeight: 21,
        itemGap: 24,
        textStyle: {
          fontSize: 18
        }
      },
      series: {
        left: "center",
        top: 120,
        radius: ["84%", "120%"],
        label: {
          fontSize: 18
        }
      }
    }),
      (that.stylesBar = {
        xAxis: {
          axisLabel: {
            fontSize: 13
          }
        },
        yAxis: {
          axisLabel: {
            fontSize: 16
          }
        },
        series: {
          barWidth: 22
        }
      }),
      (that.styleLine = {
        xAxis: {
          axisLabel: {
            fontSize: 16
          }
        },
        yAxis: {
          axisLabel: {
            fontSize: 16
          }
        },
        title: {
          textStyle: {
            fontSize: 30
          }
        },
        legend: {
          let: 0,
          top: 30,
          itemWidth: 16,
          itemHeight: 16,
          textStyle: {
            fontSize: 14
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "4%",
          top: "32%",
          containLabel: true
        }
      }),
      (that.styleBarLine = {
        legend: {
          right: 10,
          itemWidth: 38,
          itemHeight: 16,
          textStyle: {
            fontFamily: "BrilliantCut-Regular",
            fontSize: 17,
            color: "rgba(128, 128, 128, 1)"
          }
        },
        xAxis: {
          axisLabel: {
            fontSize: 16
          }
        },
        grid: {
          top: '15%',
          bottom: "15%"
        },
      });
  },
  beforeDestroy() {
    clearInterval(this.setInterval);
  }
};
</script>
<style scoped>
h2 {
  font-size: 20px;
  text-transform: uppercase;
  font-family: BrilliantCut-Bold;
  font-weight: 400;
}

.time {
  font-family: BrilliantCut-Bold;
  font-size: 24px;
  color: #969696;
}

.box {
  overflow: hidden;
  border-radius: 15px;
  padding: 0 35px;
  box-sizing: border-box;
}

.section-left {
  margin: 0 30px;
  padding-top: 96px;
}

.section-right {
  margin: 0 30px;
}

.section-right .piece:first-child .card {
  height: 1392px;
}

.section-right .piece:first-child .box {
  height: 100%;
}

.section-left .box {
  width: 100%;
  background: #f7e9e9;
  margin-bottom: 40px;
}

.section-left .box>.title {
  font-size: 24px;
  font-family: BrilliantCut-Black;
}

.section-left .box-epie {
  height: 924px;
}

.section-left .box-epie>.title {
  border-top: 1px solid #101e2c;
  padding-top: 16px;
  margin-top: 16px;
  margin-bottom: 40px;
}

h2 {
  font-size: 32px;
  font-weight: bold;
}

.box-epie h2 {
  /* font-size: 20px; */
  margin-top: 63px;
  margin-bottom: 4px;
}

.box-table .time {
  margin-left: 50px;
}

.box-table {
  height: 100%;
  padding: 66px 35px !important;
}

.box-table .length {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5dcdc;
  font-size: 24px;
  padding: 0 30px 0 9px;
  margin: 12px 0 40px 0;
  font-family: BrilliantCut-Regular;
}

.box-table .length .numb {
  height: 26px;
  line-height: 26px;
  overflow: hidden;
  margin-right: 6px;
}

.table-tt {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: BrilliantCut-Black;
}

.table-tt .numb {
  height: 20px;
  margin: 0 4px;
  overflow: hidden;
  line-height: 22px;
}

.section-right .table-tt {
  margin-top: 38px;
  margin-bottom: 23px;
  font-size: 23px;
}

.card {
  position: relative;
  width: 690px;
  height: 796px;
  margin-bottom: 40px;
  -webkit-perspective: 1500;
  perspective: 1500;
}

.card .box {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  opacity: 0;
}

.card .just {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.card .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card .box.active {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.card .btns {
  position: absolute;
  width: 100%;
  bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: BrilliantCut-Regular;
}

.card .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 60px;
  font-size: 21px;
  color: #000;
  margin: 0 10px;
  border: 1px solid #000;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.card .btn span {
  position: relative;
  z-index: 1;
  pointer-events: none;
  text-align: center;
}

.card .btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  left: 0;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.card .btn.active {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.card .btn.active::before {
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}

.piece>h2 {
  height: 82px;
  line-height: 82px;
  text-align: center;
  background-color: #e8e8e8;
  border-radius: 15px;
}

.section-right .box {
  background-color: #f5f5f5;
  margin-top: 10px;
}

/* .section-right .top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
} */
.section-right .top .piece:first-child,
.section-right .top .piece:first-child .card {
  width: 100%;
}

.section-right .top .box,
.section-right .card {
  height: 647px;
  width: 100%;
}

.section-right .bottom {
  margin-top: 24px;
  padding-bottom: 40px;
}

.section-right .bottom .piece .box {
  width: 100%;
  height: 722px;
}

.section-right .bottom .card {
  height: 722px !important;
}

.section-right .bottom .btns {
  bottom: 50px;
}

.section-right .line-tt {
  padding-top: 50px;
  font-size: 30px;
  font-family: 'BrilliantCut-Black';
}

.section-right .bottom .card {
  height: 722px;
}

/* mobile */
/deep/#Header {
  height: 96px;
  display: flex;
  align-items: center !important;
  justify-content: center;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 99;
  margin-bottom: 0;
}

/deep/#Header h1 {
  display: none;
}

/deep/#Header img {
  height: 42px !important;
  bottom: auto;
  left: 50%;
  position: initial !important;
}

/deep/.box-epie #EchartPie {
  height: 600px;
  width: 604px;
}

/deep/.box-epie .day {
  left: 210px;
  margin-top: 56px;
}

/deep/.box-epie .day .lb {
  font-size: 20px;
}

/deep/.box-epie .day .nb {
  font-size: 32px;
  height: 34px;
}

/deep/.box-epie .day p {
  line-height: 34px;
}

/deep/.box-epie .day .run {
  transform: translateY(-34px);
}

/deep/.taBle .hd,
/deep/.taBle .bd .line {
  height: 54px;
  font-size: 22px;
}

/deep/.section-right .bd .line {
  height: 52px !important;
}

/deep/.taBle .hd .line {
  font-size: 22px;
}

/deep/.taBle .line .cont p {
  white-space: nowrap;
  line-height: 28px !important;
}

/deep/.taBle .cont {
  height: 28px;
}

/deep/.taBle .cont .run {
  transform: translateY(-28px);
}

/deep/.taBle .bd .line .ic {
  width: 12px;
  height: 21px;
}

/deep/.taBle .bd .line .ic.up {
  background-position: 0 -20px;
}

/deep/#EchartBar {
  width: 600px;
  height: 174px;
}

/deep/.section-left .tip {
  font-size: 22px;
  top: -36px;
}

/deep/.section-right .EchartLine {
  width: 600px;
  height: 400px;
}

/deep/.section-right .EchartBarLine {
  width: 600px;
  height: 500px;
}
</style>
